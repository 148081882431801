const status = 0;

export const statusReducer = (state = status, action) => {
  switch (action.type) {
    case "SET_STATUS":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export default statusReducer;
