import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  FormControl,
  InputBase,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  TablePagination,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core/";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { lightBlue, red, teal, yellow, grey } from "@material-ui/core/colors";
import PatientButtonStatus from "../../../utility/components/appointment_status";
import BorderLinearProgress from "../../../utility/components/progrssbar";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import style from "./style";
import MainMenu from "../main_menu";
import male from "../../../assets/images/pic-icon-male.png";
import female from "../../../assets/images/pic-icon-female.png";
import {
  ConvertToModel,
  convertAppointmentType,
  convertAppoientmentStatus,
} from "../../../utility/appointment";
import { convertDateTimeToStringTH } from "../../../utility/time";
import { AppointmentGetByDate } from "../../../dataservice/appointment/appointment";
import screeningLogo from "../../../assets/images/pic-icon-screening.png";
import firstVisitLogo from "../../../assets/images/pic-icon-training.png";
import followOneLogo from "../../../assets/images/pic-icon-follow-up-1.png";
import followTwoLogo from "../../../assets/images/pic-icon-follow-up-2.png";
import { PatientsSummary } from "../../../dataservice/patient/patient";
import {
  APPOINTMENTTYPE,
  APPOINTMENTSTATUS,
  PATIENTSTATUS,
} from "../../../utility/const";

let Home = (props) => {
  const classes = makeStyles(style)();
  const [index, setIndex] = useState(0);
  const [status, setStatus] = useState(undefined);
  const [screeningSummary, setScreeningSummary] = useState(0);
  const [firstVisitSummary, setFirstVisitSummary] = useState(0);
  const [followUpOneSummary, setFollowUpOneSummary] = useState(0);
  const [followUpTwoSummary, setFollowUpTwoSummary] = useState(0);
  const [completeSummary, setCompleteSummary] = useState(0);
  const [numberOfScreening, setNumberOfScreening] = useState(0);
  const [numberOfFirstVisit, setNumberOfFirstVisit] = useState(0);
  const [numberOfFollowUpOne, setNumberOfFollowUpOne] = useState(0);
  const [numberOfFollowUpTwo, setNumberOfFollowUpTwo] = useState(0);
  const [numberOfScreeningFulfilled, setNumberOfScreeningFulfilled] =
    useState(0);
  const [numberOfFirstVisitFulfilled, setNumberOfFirstVisitFulfilled] =
    useState(0);
  const [numberOfFollowUpOneFulfilled, setNumberOfFollowUpOneFulfilled] =
    useState(0);
  const [numberOfFollowUpTwoFulfilled, setNumberOfFollowUpTwoFulfilled] =
    useState(0);
  const [numberOfAppointmentOnDay, setNumberOfAppointmentOnDay] = useState(0);
  const [numberOfAppointment, setNumberOfAppointment] = useState(0);
  const [numberOfAppointmentFulfilled, setNumberOfAppointmentFulfilled] =
    useState(0);
  const [date, setDate] = useState(new Date());
  const [tablePage, setTablePage] = useState(0);
  const [tableRowPerPage, setTableRowsPerPage] = useState(10);
  const [appointments, setAppointments] = useState([]);
  const [showAppointments, setShowAppointments] = useState(appointments);
  const [searchString, setSearchString] = useState("");
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };
  const appointment_columns = [
    {
      id: "date",
      label: "วันที่",
      minWidth: 170,
    },
    {
      id: "firstName",
      label: "ชื่อ",
      minWidth: 170,
    },
    {
      id: "lastName",
      label: "นามสกุล",
      minWidth: 170,
    },
    {
      id: "sex",
      label: "เพศ",
      minWidth: 170,
    },
    {
      id: "group",
      label: "กลุ่ม",
      minWidth: 170,
    },
    {
      id: "phone",
      label: "ติดต่อ",
      minWidth: 170,
    },
    {
      id: "status",
      label: "สถานะ",
      minWidth: 170,
    },
  ];

  useEffect(() => {
    async function fetchData() {
      let appointmentScreening = 0;
      let appointmentFirstVisit = 0;
      let appointmentFollowUpOne = 0;
      let appointmentFollowUpTwo = 0;
      let appointmentScreeningFulfilled = 0;
      let appointmentFirstVisitFulfilled = 0;
      let appointmentFollowUpOneFulfilled = 0;
      let appointmentFollowUpTwoFulfilled = 0;
      await PatientsSummary(props.user.location.id).then((res) => {
        setScreeningSummary(res.data.screening);
        setFirstVisitSummary(res.data.firstVisit);
        setFollowUpOneSummary(res.data.followUpOne);
        setFollowUpTwoSummary(res.data.followUpTwo);
        setCompleteSummary(res.data.complete);
      });
      await AppointmentGetByDate(
        props.user.location.id,
        convertDateTimeToStringTH(date)
      ).then((res) => {
        let appointmentObjects = res.data.entry.map((entry) => {
          return ConvertToModel(entry.resource);
        });
        appointmentObjects.forEach((appointment) => {
          switch (appointment.type) {
            case APPOINTMENTTYPE.SCREENING:
              appointmentScreening += 1;
              if (appointment.status === APPOINTMENTSTATUS.fulfilled) {
                appointmentScreeningFulfilled += 1;
              }
              break;
            case APPOINTMENTTYPE.FIRSTVISIT:
              appointmentFirstVisit += 1;
              if (appointment.status === APPOINTMENTSTATUS.fulfilled) {
                appointmentFirstVisitFulfilled += 1;
              }
              break;
            case APPOINTMENTTYPE.FOLLOWUP1:
              appointmentFollowUpOne += 1;
              if (appointment.status === APPOINTMENTSTATUS.fulfilled) {
                appointmentFollowUpOneFulfilled += 1;
              }
              break;
            case APPOINTMENTTYPE.FOLLOWUP2:
              appointmentFollowUpTwo += 1;
              if (appointment.status === APPOINTMENTSTATUS.fulfilled) {
                appointmentFollowUpTwoFulfilled += 1;
              }
              break;
            default:
              break;
          }
        });
        setNumberOfScreening(appointmentScreening);
        setNumberOfFirstVisit(appointmentFirstVisit);
        setNumberOfFollowUpOne(appointmentFollowUpOne);
        setNumberOfFollowUpTwo(appointmentFollowUpTwo);
        setNumberOfScreeningFulfilled(appointmentScreeningFulfilled);
        setNumberOfFirstVisitFulfilled(appointmentFirstVisitFulfilled);
        setNumberOfFollowUpOneFulfilled(appointmentFollowUpOneFulfilled);
        setNumberOfFollowUpTwoFulfilled(appointmentFollowUpTwoFulfilled);
        setNumberOfAppointmentOnDay(
          appointmentScreening +
            appointmentFirstVisit +
            appointmentFollowUpOne +
            appointmentFollowUpTwo
        );
        setNumberOfAppointment(
          appointmentScreening +
            appointmentFirstVisit +
            appointmentFollowUpOne +
            appointmentFollowUpTwo
        );
        setNumberOfAppointmentFulfilled(
          appointmentScreeningFulfilled +
            appointmentFirstVisitFulfilled +
            appointmentFollowUpOneFulfilled +
            appointmentFollowUpTwoFulfilled
        );
        if (status) {
          switch (status) {
            case PATIENTSTATUS.HIDDENSTATUS:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.SCREENING
              );
              setNumberOfAppointment(appointmentScreening);
              setNumberOfAppointmentFulfilled(appointmentScreeningFulfilled);
              break;
            case PATIENTSTATUS.FIRSTVISIT:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.FIRSTVISIT
              );
              setNumberOfAppointment(appointmentFirstVisit);
              setNumberOfAppointmentFulfilled(appointmentFirstVisitFulfilled);
              break;
            case PATIENTSTATUS.FOLLOWUP1:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.FOLLOWUP1
              );
              setNumberOfAppointment(appointmentFollowUpOne);
              setNumberOfAppointmentFulfilled(appointmentFollowUpOneFulfilled);
              break;
            case PATIENTSTATUS.FOLLOWUP2:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.FOLLOWUP2
              );
              setNumberOfAppointment(appointmentFollowUpTwo);
              setNumberOfAppointmentFulfilled(appointmentFollowUpTwoFulfilled);
              break;
            default:
              break;
          }
        }
        setAppointments(appointmentObjects);
        setShowAppointments(appointmentObjects);
      });
    }
    fetchData();
  }, [status, date, props.user.location.id]);

  const searchAppointment = () => {
    let temp_data = [...appointments];
    if (searchString !== "") {
      temp_data = temp_data.filter(
        (appointment) =>
          appointment.firstName.includes(searchString.trim()) ||
          appointment.lastName.includes(searchString.trim()) ||
          appointment.group.toString() === searchString
      );
    }
    setShowAppointments(temp_data);
  };

  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setTableRowsPerPage(parseInt(event.target.value, 10));
    setTablePage(0);
  };

  const convertAppointmentTypeColor = (type) => {
    switch (type) {
      case APPOINTMENTTYPE.SCREENING:
        return teal;
      case APPOINTMENTTYPE.FIRSTVISIT:
        return lightBlue;
      case APPOINTMENTTYPE.FOLLOWUP1:
        return yellow;
      case APPOINTMENTTYPE.FOLLOWUP2:
        return red;
      default:
        return grey;
    }
  };

  let table = (
    <Grid item xs={12}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {appointment_columns.map((column) => (
                <TableCell key={column.id} className={classes.table_cell}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showAppointments
              .slice(
                tablePage * tableRowPerPage,
                tablePage * tableRowPerPage + tableRowPerPage
              )
              .map((appointment, index) => (
                <TableRow hover key={index} style={{ cursor: "pointer" }}>
                  <TableCell className={classes.table_cell}>
                    {appointment.date}
                  </TableCell>
                  <TableCell className={classes.table_cell}>
                    {appointment.firstName}
                  </TableCell>
                  <TableCell className={classes.table_cell}>
                    {appointment.lastName}
                  </TableCell>
                  <TableCell className={classes.table_cell}>
                    {appointment.gender === 1 ? (
                      <img src={male} alt="sex" className={classes.sex} />
                    ) : (
                      <img src={female} alt="sex" className={classes.sex} />
                    )}
                  </TableCell>
                  <TableCell className={classes.table_cell}>
                    {appointment.group}
                  </TableCell>
                  <TableCell className={classes.table_cell}>
                    {appointment.phone}
                  </TableCell>
                  <TableCell className={classes.table_cell}>
                    {!status ? (
                      <PatientButtonStatus
                        className={classes.appointment_status_button}
                        label={convertAppointmentType(appointment.type)}
                        statuscolor={
                          convertAppointmentTypeColor(appointment.type)["A700"]
                        }
                        backgroundstatuscolor={
                          convertAppointmentTypeColor(appointment.type)["A100"]
                        }
                        disableRipple
                      />
                    ) : (
                      <div
                        style={{
                          color:
                            appointment.status === APPOINTMENTSTATUS.fulfilled
                              ? teal["A700"]
                              : grey,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CheckCircleIcon
                          style={{
                            display:
                              appointment.status === APPOINTMENTSTATUS.fulfilled
                                ? "block"
                                : "none",
                          }}
                        />
                        <Typography>
                          {convertAppoientmentStatus(appointment.status)}
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

  let home = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} style={{ padding: 16 }}>
          <Grid item xs={9} align="left">
            <Grid container direction="row" justify="space-around">
              <Grid item xs={3} style={{ padding: 16 }}>
                <Paper
                  style={{
                    padding: 24,
                    borderRadius: 24,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      className={classes.logo}
                      src={screeningLogo}
                      alt="screening-logo"
                    />
                    <div style={{ margin: "auto" }}>
                      <Typography variant="h3">{`${firstVisitSummary}/${screeningSummary}`}</Typography>
                      <Typography style={{ color: "#9e9e9e" }}>
                        การคัดกรอง
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={3} style={{ padding: 16 }}>
                <Paper
                  style={{
                    padding: 24,
                    borderRadius: 24,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      className={classes.logo}
                      src={firstVisitLogo}
                      alt="firstVisit-logo"
                    />
                    <div style={{ margin: "auto" }}>
                      <Typography variant="h3">{`${followUpOneSummary}/${firstVisitSummary}`}</Typography>
                      <Typography style={{ color: "#9e9e9e" }}>
                        การอบรมครั้งแรก
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={3} style={{ padding: 16 }}>
                <Paper
                  style={{
                    padding: 24,
                    borderRadius: 24,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      className={classes.logo}
                      src={followOneLogo}
                      alt="followOne"
                    />
                    <div style={{ margin: "auto" }}>
                      <Typography variant="h3">{`${followUpTwoSummary}/${followUpOneSummary}`}</Typography>
                      <Typography style={{ color: "#9e9e9e" }}>
                        ติดตามผลครั้งที่ 1
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={3} style={{ padding: 16 }}>
                <Paper
                  style={{
                    padding: 24,
                    borderRadius: 24,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      className={classes.logo}
                      src={followTwoLogo}
                      alt="followTwo"
                    />
                    <div style={{ margin: "auto" }}>
                      <Typography variant="h3">{`${completeSummary}/${followUpTwoSummary}`}</Typography>
                      <Typography style={{ color: "#9e9e9e" }}>
                        ติดตามผลครั้งที่ 2
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9} align="left">
            <Typography variant="h4">
              {convertDateTimeToStringTH(date)} [{numberOfAppointmentOnDay} ราย]
            </Typography>
          </Grid>
          <Grid item xs={9} align="left">
            <Paper
              style={{
                borderRadius: 24,
              }}
            >
              <Grid item xs={12} style={{ display: "flex", padding: 24 }}>
                <Typography
                  variant="h6"
                  style={{
                    flexGrow: 1,
                    margin: "auto",
                  }}
                >
                  รายชื่อนัดประจำวัน
                </Typography>
                <FormControl
                  style={{
                    flexGrow: 1,
                    marginRight: 40,
                    backgroundColor: "#eeeeee",
                    padding: 8,
                    borderRadius: 16,
                  }}
                >
                  <InputBase
                    placeholder="ค้นหารายชื่อ,กลุ่ม"
                    style={{ marginLeft: 16 }}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </FormControl>
                <Button
                  className={classes.search_button}
                  onClick={searchAppointment}
                >
                  <Typography>ค้นหา</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <Tabs
                  value={index}
                  indicatorColor="primary"
                  onChange={(event, newIndex) => {
                    setIndex(newIndex);
                  }}
                  aria-label="simple tabs example"
                  style={{ flexGrow: 1 }}
                >
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="ทั้งหมด"
                    {...a11yProps(0)}
                    onClick={() => {
                      setStatus(undefined);
                    }}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="การคัดกรอง"
                    {...a11yProps(1)}
                    onClick={() => {
                      setStatus(PATIENTSTATUS.HIDDENSTATUS);
                    }}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="การอบรมครั้งแรก"
                    {...a11yProps(3)}
                    onClick={() => {
                      setStatus(PATIENTSTATUS.FIRSTVISIT);
                    }}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="ติดตามผลครั้งที่ 1"
                    {...a11yProps(4)}
                    onClick={() => {
                      setStatus(PATIENTSTATUS.FOLLOWUP1);
                    }}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="ติดตามผลครั้งที่ 2"
                    {...a11yProps(5)}
                    onClick={() => {
                      setStatus(PATIENTSTATUS.FOLLOWUP2);
                    }}
                  />
                </Tabs>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={showAppointments.length}
                  rowsPerPage={tableRowPerPage}
                  page={tablePage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", padding: "0px 24px 24px 24px" }}
              >
                <Grid
                  container
                  justify="space-around"
                  style={{
                    padding: 24,
                    borderRadius: 16,
                    backgroundColor: "#eeeeee",
                    flexGrow: 1,
                  }}
                >
                  <Grid style={{ display: "flex" }}>
                    <Typography style={{ margin: "auto" }}>ทั้งหมด</Typography>
                    <Typography variant="h3" style={{ marginLeft: 24 }}>
                      {numberOfAppointment}
                    </Typography>
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <Typography style={{ margin: "auto" }}>
                      เสร็จแล้ว
                    </Typography>
                    <Typography variant="h3" style={{ marginLeft: 24 }}>
                      {numberOfAppointmentFulfilled}
                    </Typography>
                  </Grid>
                  <Grid style={{ display: "flex" }}>
                    <Typography style={{ margin: "auto" }}>คงเหลือ</Typography>
                    <Typography variant="h3" style={{ marginLeft: 24 }}>
                      {numberOfAppointment - numberOfAppointmentFulfilled}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TabPanel value={index} index={0}>
                  {table}
                </TabPanel>
                <TabPanel value={index} index={1}>
                  {table}
                </TabPanel>
                <TabPanel value={index} index={2}>
                  {table}
                </TabPanel>
                <TabPanel value={index} index={3}>
                  {table}
                </TabPanel>
                <TabPanel value={index} index={4}>
                  {table}
                </TabPanel>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2} container direction="column">
            <Grid>
              <Paper
                style={{
                  padding: "48px 24px",
                  marginBottom: 32,
                  borderRadius: 24,
                }}
              >
                <Grid item xs={12} align="left">
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <Typography variant="h6">ความคืบหน้า</Typography>
                  </div>
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <Typography style={{ flexGrow: 1 }}>การคัดกรอง</Typography>
                    <Typography>
                      {numberOfScreeningFulfilled}/{numberOfScreening}
                    </Typography>
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      (numberOfScreeningFulfilled * 100) / numberOfScreening
                    }
                    barcolor={teal["A400"]}
                  />
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <Typography style={{ flexGrow: 1 }}>
                      การอบรมครั้งแรก
                    </Typography>
                    <Typography>
                      {numberOfFirstVisitFulfilled}/{numberOfFirstVisit}
                    </Typography>
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      (numberOfFirstVisitFulfilled * 100) / numberOfFirstVisit
                    }
                    barcolor={lightBlue["A200"]}
                  />
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <Typography style={{ flexGrow: 1 }}>
                      ติดตามผลครั้งที่ 1
                    </Typography>
                    <Typography>
                      {numberOfFollowUpOneFulfilled}/{numberOfFollowUpOne}
                    </Typography>
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      (numberOfFollowUpOneFulfilled * 100) / numberOfFollowUpOne
                    }
                    barcolor={yellow["A700"]}
                  />
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <Typography style={{ flexGrow: 1 }}>
                      ติดตามผลครั้งที่ 2
                    </Typography>
                    <Typography>
                      {numberOfFollowUpTwoFulfilled}/{numberOfFollowUpTwo}
                    </Typography>
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      (numberOfFollowUpTwoFulfilled * 100) / numberOfFollowUpTwo
                    }
                    barcolor={red["A700"]}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid>
              <Paper
                style={{
                  padding: 16,
                  borderRadius: 24,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    disableToolbar
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={setDate}
                  />
                </MuiPickersUtilsProvider>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
  return <div>{home}</div>;
};

const mapStateToProps = (state) => ({
  language: state.language,
  user: state.user,
});

export default connect(mapStateToProps, undefined)(Home);
