import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const PatientButtonStatus = (props) => {
  let PatientButtonStatus = withStyles((theme) => ({
    root: {
      width: 150,
      color: props.statuscolor,
      backgroundColor: props.backgroundstatuscolor,
      "&:hover": {
        backgroundColor: props.backgroundstatuscolor,
      },
    },
  }))(Button);

  return (
    <React.Fragment>
      <PatientButtonStatus {...props}>{props.label}</PatientButtonStatus>
    </React.Fragment>
  );
};

export default PatientButtonStatus;
