import { BACKENDURL } from "../utility/config";

export const Authentication = () => BACKENDURL + "/authentication";
export const Appointment = () => BACKENDURL + "/appointment";
export const Email = () => BACKENDURL + "/email";
export const Location = () => BACKENDURL + "/location";
export const Observation = () => BACKENDURL + "/observation";
export const Patient = () => BACKENDURL + "/patient";
export const MPGroup = () => BACKENDURL + "/mpgroup";
export const HistoryTaking = () => BACKENDURL + "/historytaking";
