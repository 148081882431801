const styles = (theme) => ({
  root: {
    textAlign: "center",
  },
  body: {
    width: "100%",
    minWidth: 240,
    height: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.common.white,
  },
  body_paper: {
    backgroundColor: theme.palette.common.white,
  },
  logo: {
    width: 320,
    height: 265,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "90%",
    maxWidth: 600,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  login_button: {
    width: "90%",
    maxWidth: 600,
    minHeight: 56,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    color: "white",
    marginBottom: theme.spacing(1),
  },
  forget_password: {
    width: "90%",
    maxWidth: 600,
    backgroundColor: "#282c34",
    "&:hover": {
      backgroundColor: "#282c34",
    },
    color: "white",
    marginBottom: theme.spacing(1),
  },
});

export default styles;
