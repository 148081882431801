import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  Typography,
} from "@material-ui/core/";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import SearchIcon from "@material-ui/icons/Search";
import male from "../../../assets/images/pic-icon-male.png";
import female from "../../../assets/images/pic-icon-female.png";
import axios from "axios";
import { BACKENDURL } from "../../../utility/config"
import style from "./style";
import MainMenu from "../main_menu";
import { setPatient } from "../../../redux/actions/patient";
import { ConvertToModel } from "../../../utility/patient"

const columns = [
  { id: "id", label: "ลำดับ", minWidth: 50, maxWidth: 50 },
  { id: "group", label: "กลุ่ม", minWidth: 50, maxWidth: 50 },
  { id: "gender", label: "เพศ", minWidth: 50, maxWidth: 50 },
  { id: "stage1", label: "Pre-Screening", minWidth: 50, maxWidth: 50 },
  { id: "stage2", label: "Screening", minWidth: 50, maxWidth: 50 },
  { id: "stage3", label: "1st Visit", minWidth: 50, maxWidth: 50 },
  { id: "stage4", label: "1st Followup", minWidth: 50, maxWidth: 50 },
  { id: "stage5", label: "2nd Followup", minWidth: 50, maxWidth: 50 },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) =>
          !column.id.includes("stage") ? (
            <TableCell
              key={column.id}
              style={{ minWidth: column.minWeight, maxWidth: column.maxWidth }}
              className={classes.table_head_cell}
              align={column.numeric ? "right" : "center"}
              padding={column.disablePadding ? "none" : "default"}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={false}
                direction={
                  orderBy === column.id
                    ? order === "asc"
                      ? "desc"
                      : "asc"
                    : "desc"
                }
                onClick={createSortHandler(column.id)}
                classes={{
                  icon: classes.sort_color,
                }}
              >
                {column.label}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
              <TableCell
                key={column.id}
                style={{ minWidth: column.minWeight, maxWidth: column.maxWidth }}
                className={classes.table_head_cell}
                align={column.numeric ? "right" : "center"}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.label}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

let Record = (props) => {
  const [rows, setRows] = useState([]);
  const [showRows, setShowRows] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await axios.get(BACKENDURL + "/patient?")
        .then((res) => {
          let patientObjects = res.data.entry.map((entry) =>
            ConvertToModel(entry.resource)
          );
          console.log(patientObjects)
          setRows(patientObjects);
          setShowRows(patientObjects);
        });
    }
    fetchData();
  }, []);

  const classes = makeStyles(style)();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSearchDialog, setIsSearchDialog] = useState(false);
  const [searchGroup, setSearchGroup] = useState(-1);
  const [searchGender, setSearchGender] = useState(-1);

  let history = useHistory();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function onChangePage(e, newPage) {
    setPage(newPage);
  }
  function onChangeRowsPerPage(e) {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }

  function onSubmitSearch() {
    setIsSearchDialog(false);
    var tempRows = rows;
    console.log(tempRows);
    if (searchGroup !== -1) {
      tempRows = tempRows.filter(
        (tempRow) => tempRow.group === searchGroup
      );
    }
    console.log(searchGender);
    if (searchGender !== "-1") {
      tempRows = tempRows.filter(
        (filteredGroupRow) => filteredGroupRow.gender === searchGender
      );
    }
    console.log(tempRows);
    setShowRows(tempRows);
  }

  const dialogSearch = (
    <React.Fragment>
      <Dialog
        open={isSearchDialog}
        onClose={() => setIsSearchDialog(false)}
        aria-labelledby="form-dialog-title"
        style={{ textAlign: "center" }}
      >
        <DialogTitle id="form-dialog-title">
          {props.language === "en" ? "Search" : "ค้นหา"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.root}>
            <Grid item sm={12} xs={12} className={classes.root}>
              <div className={classes.search_group}>
                <Typography variant="h6">
                  {props.language === "en" ? "Select Group" : "เลือกกลุ่ม"}
                </Typography>
                <FormControl className={classes.search_group}>
                  <Select
                    native
                    onChange={(e) => setSearchGroup(parseInt(e.target.value))}
                    value={searchGroup}
                  >
                    <option value={-1}>
                      {props.language === "en" ? "No Group" : "ไม่เลือกกลุ่ม"}
                    </option>
                    <option value={0}>
                      {props.language === "en" ? "No Group" : "ไม่มีกลุ่ม"}
                    </option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item sm={12} xs={12} className={classes.root}>
              <div className={classes.search_gender}>
                <Typography variant="h6">
                  {props.language === "en" ? "Select Gender" : "เลือกเพศ"}
                </Typography>
                <FormControl className={classes.search_gender}>
                  <Select
                    native
                    onChange={(e) => setSearchGender(e.target.value)}
                    value={searchGender}
                  >
                    <option value={-1}>
                      {props.language === "en"
                        ? "No Select Gender"
                        : "ไม่เลือกเพศ"}
                    </option>
                    <option value={"male"}>
                      {props.language === "en" ? "Male" : "ชาย"}
                    </option>
                    <option value={"female"}>
                      {props.language === "en" ? "Female" : "หญิง"}
                    </option>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsSearchDialog(false)}
            className={classes.cancel_search}
          >
            {props.language === "en" ? "Cancel" : "ยกเลิก"}
          </Button>
          <Button onClick={onSubmitSearch} className={classes.confirm_search}>
            {props.language === "en" ? "Search" : "ค้นหา"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  let record = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Button
              className={classes.search_button}
              onClick={() => setIsSearchDialog(true)}
            >
              <SearchIcon />
              {props.language === "en" ? "Search" : "ค้นหา"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={2}>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className={classes.table}
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(showRows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          className={classes.table_row}
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          onClick={() => {
                            props.setPatient({
                              id: row.id,
                              nationalID: row.nationalID,
                              firstName: row.firstName,
                              lastName: row.lastName,
                              sex: row.gender === "male" ? 1 : 0,
                              birthDate: row.birthDate,
                              status: row.status,
                              wristBandId: row.wristBandId,
                            });
                            history.push("/record_patient");
                          }}
                        >
                          <TableCell className={classes.table_cell}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.table_cell}>
                            {row.group}
                          </TableCell>
                          <TableCell className={classes.table_cell}>
                            {row.gender === "male" ? (
                              <img
                                src={male}
                                alt="sex"
                                className={classes.sex}
                              />
                            ) : (
                                <img
                                  src={female}
                                  alt="sex"
                                  className={classes.sex}
                                />
                              )}
                          </TableCell>
                          <TableCell className={classes.table_cell}>
                            {row.status >= 1 ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                          </TableCell>
                          <TableCell className={classes.table_cell}>
                            {row.status >= 2 ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                          </TableCell>
                          <TableCell className={classes.table_cell}>
                            {row.status >= 3 ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                          </TableCell>
                          <TableCell className={classes.table_cell}>
                            {row.status >= 4 ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                          </TableCell>
                          <TableCell className={classes.table_cell}>
                            {row.status >= 5 ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={showRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
              />
            </Paper>
          </Grid>
          {dialogSearch}
        </Grid>
      </div>
    </div>
  );

  return <div>{record}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
  patient: state.patient,
});

const mapDispatchToProps = (dispatch) => ({
  setPatient: (data) => dispatch(setPatient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Record);
