import React, { useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Select,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import style from "./style";
import maleAvatar from "../../../../assets/images/pic-man-profile.png";
import femaleAvatar from "../../../../assets/images/pic-woman-profile.png";
import MainMenu from "../../main_menu";
import { HistoryTakingAddAnswer } from "../../../../dataservice/historytaking/historytaking";
import { UpdatePatientStatus } from "../../../../dataservice/patient/patient";
let HistoryTaking = (props) => {
  const classes = makeStyles(style)();
  const [submitDialog, setSubmitDialog] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [systolicPressure, setSystolicPressure] = useState(-1);
  const [diastolicPressure, setDiastolicPressure] = useState(-1);
  const [restingHeartRate, setRestingHeartRate] = useState(-1);
  const [weight, setWeight] = useState(-1);
  const [height, setHeight] = useState(-1);
  const [isHypertension, setIsHypertension] = useState(-1);
  const [isDiabetes, setIsDiabetes] = useState(-1);
  const [isDiabetesInFamily, setIsDiabetesInFamily] = useState(-1);
  const [isCardiovascular, setIsCardiovascular] = useState(-1);
  const [isPulmonary, setIsPulmonary] = useState(-1);
  const [isKidneyFailure, setIsKidneyFailure] = useState(-1);
  const [isCerebrovascular, setIsCerebrovascular] = useState(-1);
  const [isCancer, setIsCancer] = useState(-1);
  const [isDepress, setIsDepress] = useState(-1);
  const [isOnPsychiatryDrug, setIsOnPsychiatryDrug] = useState(-1);
  const [isEffectOnIsulin, setIsEffectOnIsulin] = useState(-1);
  const [isAbnormalMuscleTone, setIsAbnormalMuscleTone] = useState(-1);
  const [rejects, setRejects] = useState([]);
  let history = useHistory();

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const validate = () => {
    const validate =
      systolicPressure !== -1 &&
      diastolicPressure !== -1 &&
      restingHeartRate !== -1 &&
      weight !== -1 &&
      height !== -1 &&
      isHypertension !== -1 &&
      isDiabetes !== -1 &&
      isDiabetesInFamily !== -1 &&
      isCardiovascular !== -1 &&
      isPulmonary !== -1 &&
      isKidneyFailure !== -1 &&
      isCerebrovascular !== -1 &&
      isCancer !== -1 &&
      isDepress !== -1 &&
      isOnPsychiatryDrug !== -1 &&
      isEffectOnIsulin !== -1 &&
      isAbnormalMuscleTone !== -1;
    return validate;
  };
  const submitHistoryTaking = () => {
    if (validate()) {
      setSubmitDialog(true);
    } else {
      setOpenSnackBar(true);
    }
  };
  const submit = () => {
    setSubmitDialog(false);
    const bmi = parseFloat(weight) / (parseFloat(height) / 100) ** 2;
    const payload = {
      nationalID: props.patient.nationalID,
      appointmentId: props.patient.appointmentId,
      answer: {
        systolicPressure: parseFloat(systolicPressure),
        diastolicPressure: parseFloat(diastolicPressure),
        restingHeartRate: parseFloat(restingHeartRate),
        weight: parseFloat(weight),
        height: parseFloat(height),
        bmi: parseFloat(bmi),
        isDiabetes: convertToBoolean(isDiabetes),
        isDiabetesInFamily: convertToBoolean(isDiabetesInFamily),
        isHypertension: convertToBoolean(isHypertension),
        isCardiovascular: convertToBoolean(isCardiovascular),
        isPulmonary: convertToBoolean(isPulmonary),
        isCerebrovascular: convertToBoolean(isCerebrovascular),
        isCancer: convertToBoolean(isCancer),
        isKidneyFailure: convertToBoolean(isKidneyFailure),
        isDepress: convertToBoolean(isDepress),
        isOnPsychiatryDrug: convertToBoolean(isOnPsychiatryDrug),
        isEffectOnIsulin: convertToBoolean(isEffectOnIsulin),
        isAbnormalMuscleTone: convertToBoolean(isAbnormalMuscleTone),
      },
    };
    HistoryTakingAddAnswer(payload).then((res) => {
      if (res.data.data.result === "accepted") {
        return UpdatePatientStatus({
          patientId: props.patient.id,
        })
          .then((res) => {
            if (res.data.statusCode === 200) {
              history.push("/list_patient");
            } else {
              throw new Error("Network Error");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setRejects(res.data.data.result);
      }
    });
  };
  const generateWeight = () => {
    let weightOption = [];
    weightOption.push(
      <option value={-1} disabled key="weightOption">
        {props.language === "en" ? "Weight" : "น้ำหนัก"}
      </option>
    );
    for (var i = 40; i < 200; i++) {
      weightOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return weightOption;
  };
  const generateHeight = () => {
    let heightOption = [];
    heightOption.push(
      <option value={-1} disabled key="heightOption">
        {props.language === "en" ? "Height" : "ส่วนสูง"}
      </option>
    );
    for (var i = 100; i < 200; i++) {
      heightOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return heightOption;
  };
  const generateSystolic = () => {
    let systolicOption = [];
    systolicOption.push(
      <option value={-1} disabled key="systolicOption">
        ความดันตัวบน
      </option>
    );
    for (var i = 70; i < 200; i++) {
      systolicOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return systolicOption;
  };
  const generateDiastolic = () => {
    let diastolicOption = [];
    diastolicOption.push(
      <option value={-1} disabled key="diastolicOption">
        ความดันตัวล่าง
      </option>
    );
    for (var i = 50; i < 200; i++) {
      diastolicOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return diastolicOption;
  };
  const generateRestingHeartRate = () => {
    let restingHeartRateOption = [];
    restingHeartRateOption.push(
      <option value={-1} disabled key="restingHeartRateOption">
        ชีพจรขณะพัก
      </option>
    );
    for (var i = 50; i < 200; i++) {
      restingHeartRateOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return restingHeartRateOption;
  };
  const convertToBoolean = (text) => {
    return text === "true" ? true : false;
  };
  let select_questions = [
    {
      label: "น้ำหนัก",
      id: "weight",
      value: weight,
      onChangeValue: (e) => setWeight(e.target.value),
      generate: generateWeight(),
    },
    {
      label: "ส่วนสูง",
      id: "height",
      value: height,
      onChangeValue: (e) => setHeight(e.target.value),
      generate: generateHeight(),
    },
    {
      label: "ความดันตัวบน (Systolic)",
      id: "systolic",
      value: systolicPressure,
      onChangeValue: (e) => setSystolicPressure(e.target.value),
      generate: generateSystolic(),
    },
    {
      label: "ความดันตัวล่าง (Diastolic)",
      id: "diastolic",
      value: diastolicPressure,
      onChangeValue: (e) => setDiastolicPressure(e.target.value),
      generate: generateDiastolic(),
    },
    {
      label: "ชีพจรขณะพัก",
      id: "restingHeartRate",
      value: restingHeartRate,
      onChangeValue: (e) => setRestingHeartRate(e.target.value),
      generate: generateRestingHeartRate(),
    },
  ];
  let radio_questions = [
    {
      label:
        "อาสาสมัครเป็นโรคความดันโลหิตสูง หลังการรักษามาแล้วอย่างน้อย 3 เดือนมีความดันมากกว่า 140/90 ใช่หรือไม่",
      onChangeValue: (e) => setIsHypertension(e.currentTarget.value),
      id: "isHypertension",
    },
    {
      label: "อาสาสมัครเป็นโรคเบาหวานใช่หรือไม่",
      onChangeValue: (e) => setIsDiabetes(e.currentTarget.value),
      id: "isDiabetes",
    },
    {
      label: "อาสาสมัครมีประวัติครอบครัวเป็นโรคเบาหวานใช่หรือไม่",
      onChangeValue: (e) => setIsDiabetesInFamily(e.currentTarget.value),
      id: "isDiabetesInFamily",
    },
    {
      label: "อาสาสมัครเป็นโรคหัวใจใช่หรือไม่",
      onChangeValue: (e) => setIsCardiovascular(e.currentTarget.value),
      id: "isCardiovascular",
    },
    {
      label: "อาสาสมัครมีโรคปอดในระดับที่ทำให้เกิดข้อจํากัดต่อการออกกําลังกาย",
      onChangeValue: (e) => setIsPulmonary(e.currentTarget.value),
      id: "isPulmonary",
    },
    {
      label:
        "อาสาสมัครมีภาวะโรคไตเรื้อรัง ที่ค่าอัตราการกรองของไต น้อยกว่า 60 ml/min ต่อ 1.73 sq.m",
      onChangeValue: (e) => setIsKidneyFailure(e.currentTarget.value),
      id: "isKidneyFailure",
    },
    {
      label:
        "อาสาสมัครเป็นโรคหลอดเลือดสมองหรือพบอาการหรืออาการแสดงที่สงสัยจะเป็นโรคนี้",
      onChangeValue: (e) => setIsCerebrovascular(e.currentTarget.value),
      id: "isCerebrovascular",
    },
    {
      label:
        "อาสาสมัครเป็นโรคมะเร็ง ในระยะที่น่าจะเป็นอุปสรรคต่อการเข้าร่วมกิจกรรมการออกกำลังกาย",
      onChangeValue: (e) => setIsCancer(e.currentTarget.value),
      id: "isCancer",
    },
    {
      label:
        "อาสาสมัครมีภาวะซึมเศร้าในระดับที่น่าจะเป็นอุปสรรคต่อการเข้าร่วมกิจกรรมการออกกำลังกาย",
      onChangeValue: (e) => setIsDepress(e.currentTarget.value),
      id: "isDepress",
    },
    {
      label:
        "อาสาสมัครมีการใช้ยางทางจิตเวชที่ส่งผลต่อการทำงานของระบบประสาทและกล้ามเนื้อในการออกกำลังกาย",
      onChangeValue: (e) => setIsOnPsychiatryDrug(e.currentTarget.value),
      id: "isOnPsychiatryDrug",
    },
    {
      label:
        "อาสาสมัครมีการใช้ยากลุ่มสเตียรอยด์ ฮอร์โมน ยาคุมกำเนิดเป็นประจำที่กระทบต่อภาวะไวหรือภาวะดื้ออินซูลิน",
      onChangeValue: (e) => setIsEffectOnIsulin(e.currentTarget.value),
      id: "isEffectOnIsulin",
    },
    {
      label:
        "อาสาสมัครมีความผิดปกติของกล้ามเนื้อ กระดูก เอ็นและข้อต่อที่ทำให้เกิดข้อจํากัดต่อการออกกําลังกาย",
      onChangeValue: (e) => setIsAbnormalMuscleTone(e.currentTarget.value),
      id: "isAbnormalMuscleTone",
    },
  ];

  let select_form = select_questions.map((question) => (
    <Grid item xs={12} key={question.id}>
      <Typography variant={"h5"} className={classes.text_left}>
        {question.label}
      </Typography>
      <FormControl
        className={classes.textField}
        variant="outlined"
        color="primary"
      >
        <Select
          native
          label={question.label}
          value={question.value}
          name={question.label}
          onChange={question.onChangeValue}
        >
          {question.generate}
        </Select>
      </FormControl>
    </Grid>
  ));
  let radio_form = radio_questions.map((question) => (
    <Grid
      item
      md={12}
      xs={12}
      align="left"
      className={classes.spaceGrid}
      key={question.id}
    >
      <Typography variant="h5">{question.label}</Typography>
      <RadioGroup row aria-label="position">
        <FormControlLabel
          value="true"
          control={<Radio color="primary" />}
          label="ใช่"
          className={classes.radioSpace}
          onChange={question.onChangeValue}
        />
        <FormControlLabel
          value="false"
          control={<Radio color="primary" />}
          label="ไม่ใช่"
          className={classes.radioSpace}
          onChange={question.onChangeValue}
        />
      </RadioGroup>
    </Grid>
  ));

  let message_notpass = (
    <React.Fragment>
      <Typography variant={"h4"}>
        อาสาสมัครไม่ผ่านการซักประวัติ เนื่องจาก
      </Typography>
      {rejects.map((reject, index) => (
        <Typography key={index} style={{ marginBottom: 16 }}>{`${
          index + 1
        }. ${reject}`}</Typography>
      ))}
      <Button
        component={NavLink}
        to="/home"
        variant="outlined"
        type="submit"
        className={classes.action_button}
      >
        <Typography variant="h6">
          {props.language === "en" ? "Home" : "กลับสู่หน้าหลัก"}
        </Typography>
      </Button>
    </React.Fragment>
  );

  let history_talking = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} className={classes.root}>
          <Grid item xs={12} style={{ padding: 32 }}>
            <Paper className={classes.profile_header}>
              <Grid item xs={12}>
                <img
                  src={props.patient.sex === 1 ? maleAvatar : femaleAvatar}
                  alt="avatar"
                  className={classes.avatar}
                />
                <Typography variant="h5">
                  หมายเลขอาสาสมัคร : {props.patient.id}
                </Typography>
                <Typography variant="h6">
                  วันเกิด : {props.patient.birthDate}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h5"}>
                  {props.language === "en"
                    ? "Registration"
                    : "แบบสอบถามสำหรับการซักประวัติ"}
                </Typography>
              </Grid>
              <div style={{ display: rejects.length === 0 ? "block" : "none" }}>
                {select_form}
                {radio_form}
                <Grid item xs={12}>
                  <Button
                    className={classes.enter_button}
                    onClick={submitHistoryTaking}
                  >
                    บันทึกข้อมูล
                  </Button>
                </Grid>
              </div>
              <div style={{ display: rejects.length > 0 ? "block" : "none" }}>
                {message_notpass}
              </div>
            </Paper>
          </Grid>
          <div>
            <Dialog open={submitDialog} onClose={() => setSubmitDialog(false)}>
              <DialogTitle>
                {props.language === "th"
                  ? "คุณต้องการส่งข้อมูลใช่หรือไม่"
                  : "lorem ipsum"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {props.language === "th"
                    ? "โปรดตรวจสอบข้อมูลให้แน่ชัดก่อนทำการส่ง"
                    : "lorem ipsum"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setSubmitDialog(false)}
                  className={classes.dialog_button_cancel}
                >
                  {props.language === "th" ? "ยกเลิก" : "Cancel"}
                </Button>
                <Button onClick={submit} className={classes.dialog_button_send}>
                  {props.language === "th" ? "ยืนยัน" : "Send"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <Snackbar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              คุณยังกรอกข้อมูลไม่ครบ
            </Alert>
          </Snackbar>
        </Grid>
      </div>
    </div>
  );

  return <div>{history_talking}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
  patient: state.patient,
});
export default connect(mapStateToProps, undefined)(HistoryTaking);
