import { InstanceDateParser, singleUnitConverter } from "./time";
import { APPOINTMENTTYPE } from "./const";
export const ConvertToModel = (FhirAppointment) => {
  const jsDateStart = InstanceDateParser(FhirAppointment.start);
  const jsDateEnd = InstanceDateParser(FhirAppointment.end);
  let locationText = "";
  let actor = {};
  FhirAppointment.participant.forEach((participant) => {
    if (participant.actor[0].type === "Location") {
      locationText = participant.actor[0].display.name;
    }
    if (participant.actor[0].type === "Patient") {
      actor = participant.actor[0];
    }
  });
  const appointment = {
    appointmentId: parseInt(FhirAppointment.id),
    patientId: actor.reference.split("/").pop(),
    nationalID: actor.display.nationalID,
    firstName: actor.display.firstName,
    lastName: actor.display.lastName,
    group: actor.display.group,
    gender: actor.display.gender,
    phone: actor.display.phone,
    email: actor.display.email,
    wristBandId: actor.display.wristBandId,
    status: FhirAppointment.status,
    patientStatus: actor.display.status,
    patientSubStatus: actor.display.subStatus,
    birthDate: actor.display.birthDate,
    date:
      jsDateStart.getFullYear() +
      "-" +
      singleUnitConverter(jsDateStart.getMonth() + 1) +
      "-" +
      singleUnitConverter(jsDateStart.getDate()),
    start:
      jsDateStart.getHours() +
      ":" +
      singleUnitConverter(jsDateStart.getMinutes()),
    end:
      jsDateEnd.getHours() + ":" + singleUnitConverter(jsDateEnd.getMinutes()),
    location: locationText,
    type: FhirAppointment.extension[0].value,
  };
  return appointment;
};

export const convertPatientStatus = (status) => {
  switch (status) {
    case 1:
      return "การคัดกรอง";
    case 2:
      return "การคัดกรอง";
    case 3:
      return "การอบรมครั้งแรก";
    case 4:
      return "ติดตามผลครั้งที่ 1";
    case 5:
      return "ติดตามผลครั้งที่ 2";
    default:
      break;
  }
};

export const convertPatientStatusForm = (status) => {
  switch (status) {
    case 1:
      return "แบบบันทึกข้อมูลสำหรับการคัดกรอง";
    case 2:
      return "แบบบันทึกข้อมูลสำหรับการคัดกรอง";
    case 3:
      return "แบบบันทึกข้อมูลสำหรับการอบรมครั้งแรก";
    case 4:
      return "แบบบันทึกข้อมูลสำหรับการติดตามผลครั้งที่ 1";
    case 5:
      return "แบบบันทึกข้อมูลสำหรับการติดตามผลครั้งที่ 2";
    default:
      break;
  }
};

export const convertAppoientmentStatus = (status) => {
  switch (status) {
    case "booked":
      return "รอดำเนินการ";
    case "arrived":
      return "รอดำเนินการ";
    case "canceled":
      return "ยกเลิก";
    case "fulfilled":
      return "เสร็จแล้ว";
    default:
      break;
  }
};

export const convertAppointmentType = (type) => {
  switch (type) {
    case APPOINTMENTTYPE.SCREENING:
      return "การคัดกรอง";
    case APPOINTMENTTYPE.FIRSTVISIT:
      return "การอบรมครั้งแรก";
    case APPOINTMENTTYPE.FOLLOWUP1:
      return "ติดตามผลครั้งที่ 1";
    case APPOINTMENTTYPE.FOLLOWUP2:
      return "ติดตามผลครั้งที่ 2";
    default:
      break;
  }
};
