import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import style from "./style";
import maleAvatar from "../../../../assets/images/pic-man-profile.png";
import femaleAvatar from "../../../../assets/images/pic-woman-profile.png";
import MainMenu from "../../main_menu";
import { UpdatePatientStatusAndAppointmentStatus } from "../../../../dataservice/patient/patient";
import {
  PatientPostGroup,
  PatientPostWristBand,
} from "../../../../dataservice/patient/patient";
import { convertPatientStatusForm } from "../../../../utility/appointment";
import { APPOINTMENTSTATUS } from "../../../../utility/const";

let EnterFirstVisit = (props) => {
  const classes = makeStyles(style)();
  const [group, setGroup] = useState(-1);
  const [wristBandId, setWristBandId] = useState("");
  const [submitDialog, setSubmitDialog] = useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  let history = useHistory();

  useEffect(() => {
    async function fetchData() {
      if (props.patient.wristBandId !== "00000") {
        setWristBandId(props.patient.wristBandId);
        setGroup(props.patient.group);
      }
    }
    fetchData();
  }, [props.patient.wristBandId, props.patient.group, props.user.location.id]);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  function generateGroup() {
    let groupOption = [];
    groupOption.push(
      <option value={-1} disabled key="groupOption">
        {props.language === "en" ? "Group" : "กลุ่มในการวิจัย"}
      </option>
    );
    for (var i = 1; i < 4; i++) {
      groupOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return groupOption;
  }

  function validateFirstVisit() {
    const validate = wristBandId !== "" && group !== -1;
    return validate;
  }

  function onOpenSubmitDialog() {
    setSubmitDialog(true);
  }
  function onCloseSubmitDialog() {
    setSubmitDialog(false);
  }

  function onSubmitFirstVisit() {
    onCloseSubmitDialog();
    // const encouter = {
    //   reference: BACKENDURL + "/encounter/" + props.patient.appointmentId,
    //   type: "Encounter",
    // };
    // const subject = {
    //   reference: BACKENDURL + "/patient/" + props.patient.id,
    //   type: "Patient",
    // };
    // const extension = [
    //   {
    //     url: BACKENDURL + "labNumber",
    //     value: "00000000",
    //   },
    // ];
    // ObservationPostScreening({
    //   code: {
    //     coding: [
    //       {
    //         system: "https://www.snomed.org/",
    //         code: "11267006",
    //       },
    //     ],
    //   },
    //   valueQuantity: {
    //     value: ilten,
    //     unit: "pg/mL",
    //     system: "http://unitsofmeasure.org",
    //     code: "pg/mL",
    //   },
    //   encounter: encouter,
    //   subject: subject,
    //   extension: extension,
    // })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       return PatientPostWristBand({
    //         patientId: props.patient.id,
    //         wristbandId: wristBandId,
    //       });
    //     } else {
    //       throw new Error("Network Error");
    //     }
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       return PatientPostGroup({
    //         patientId: props.patient.id,
    //         group: group,
    //       });
    //     } else {
    //       throw new Error("Network Error");
    //     }
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       return UpdatePatientStatusAndAppointmentStatus({
    //         patientId: props.patient.id,
    //         appointmentId: props.patient.appointmentId,
    //       });
    //     } else {
    //       throw new Error("Network Error");
    //     }
    //   })
    //   .then((res) => {
    //     if (res.data.statusCode === 200) {
    //       history.push("/enter_success");
    //     } else {
    //       throw new Error("Network Error");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    PatientPostWristBand({
      patientId: props.patient.id,
      wristbandId: wristBandId,
    })
      .then((res) => {
        if (res.status === 200) {
          return PatientPostGroup({
            patientId: props.patient.id,
            group: group,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return UpdatePatientStatusAndAppointmentStatus({
            patientId: props.patient.id,
            appointmentId: props.patient.appointmentId,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          history.push("/enter_success");
        } else {
          throw new Error("Network Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // function onTemporarySubmit() {
  //   // const encouter = {
  //   //   reference: BACKENDURL + "/encounter/" + props.patient.appointmentId,
  //   //   type: "Encounter",
  //   // };
  //   // const subject = {
  //   //   reference: BACKENDURL + "/patient/" + props.patient.id,
  //   //   type: "Patient",
  //   // };
  //   // const extension = [
  //   //   {
  //   //     url: BACKENDURL + "labNumber",
  //   //     value: "",
  //   //   },
  //   // ];
  //   // ObservationPostScreening({
  //   //   code: {
  //   //     coding: [
  //   //       {
  //   //         system: "https://www.snomed.org/",
  //   //         code: "11267006",
  //   //       },
  //   //     ],
  //   //   },
  //   //   valueQuantity: {
  //   //     value: ilten,
  //   //     unit: "pg/mL",
  //   //     system: "http://unitsofmeasure.org",
  //   //     code: "pg/mL",
  //   //   },
  //   //   encounter: encouter,
  //   //   subject: subject,
  //   //   extension: extension,
  //   // })
  //   //   .then((res) => {
  //   //     if (res.status === 200) {
  //   //       return PatientPostWristBand({
  //   //         patientId: props.patient.id,
  //   //         wristbandId: wristBandId,
  //   //       });
  //   //     } else {
  //   //       throw new Error("Network Error");
  //   //     }
  //   //   })
  //   //   .then((res) => {
  //   //     if (res.status === 200) {
  //   //       return PatientPostGroup({
  //   //         patientId: props.patient.id,
  //   //         group: group,
  //   //       });
  //   //     } else {
  //   //       throw new Error("Network Error");
  //   //     }
  //   //   })
  //   //   .then((res) => {
  //   //     if (res.status === 200) {
  //   //       history.push("/enter_success");
  //   //     } else {
  //   //       throw new Error("Network Error");
  //   //     }
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });

  //   PatientPostWristBand({
  //     patientId: props.patient.id,
  //     wristbandId: wristBandId,
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         return PatientPostGroup({
  //           patientId: props.patient.id,
  //           group: group,
  //         });
  //       } else {
  //         throw new Error("Network Error");
  //       }
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         history.push("/enter_success");
  //       } else {
  //         throw new Error("Network Error");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  let firstvisit = (
    <React.Fragment>
      <Grid item xs={12} key="wristBandId">
        <Typography variant={"h5"} className={classes.text_left}>
          {props.language === "en" ? "Wristband ID" : "หมายเลขริสแบรนด์"}
        </Typography>
        <FormControl
          className={classes.textField}
          variant="outlined"
          color="primary"
          disabled={
            props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled
          }
        >
          <OutlinedInput
            id="wristBandId"
            placeholder={
              props.language === "en" ? "Wristband ID" : "หมายเลขริสแบรนด์"
            }
            value={wristBandId}
            onChange={(e) => setWristBandId(e.target.value)}
            labelWidth={0}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} key="group">
        <Typography variant={"h5"} className={classes.text_left}>
          {props.language === "en" ? "Group" : "กลุ่มในการวิจัย"}
        </Typography>
        <FormControl
          className={classes.textField}
          variant="outlined"
          color="primary"
        >
          <Select
            native
            label={props.language === "en" ? "Group" : "กลุ่มในการวิจัย"}
            value={group}
            name={props.language === "en" ? "Group" : "กลุ่มในการวิจัย"}
            onChange={(e) => setGroup(e.target.value)}
            disabled={
              props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled
            }
          >
            {generateGroup()}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} key="ilten">
        <Typography variant={"h5"} className={classes.text_left}>
          IL-10
        </Typography>
        <FormControl
          className={classes.textField}
          variant="outlined"
          color="primary"
        >
          <Select
            native
            label="IL-10"
            value={ilten}
            name="IL-10"
            onChange={(e) => setIlten(e.target.value)}
            disabled={
              props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled
            }
          >
            {generateILTEN()}
          </Select>
        </FormControl>
      </Grid> */}
    </React.Fragment>
  );

  let enter_firstvisit = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} className={classes.root}>
          <Grid item xs={12} style={{ padding: 32 }}>
            <Paper className={classes.profile_header}>
              <Grid item xs={12}>
                <img
                  src={props.patient.sex === 1 ? maleAvatar : femaleAvatar}
                  alt="avatar"
                  className={classes.avatar}
                />
                <Typography variant="h5">
                  หมายเลขอาสาสมัคร : {props.patient.id}
                </Typography>
                <Typography variant="h6">
                  วันเกิด : {props.patient.birthDate}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h5"}>
                  {props.language === "en"
                    ? "Registration"
                    : convertPatientStatusForm(props.patient.status)}
                </Typography>
              </Grid>
              {firstvisit}
              <Grid
                item
                xs={12}
                style={{
                  display:
                    props.patient.appointmentStatus ===
                    APPOINTMENTSTATUS.fulfilled
                      ? "none"
                      : "block",
                }}
              >
                <Button
                  onClick={() => {
                    if (validateFirstVisit()) {
                      onOpenSubmitDialog();
                    } else {
                      setOpenSnackBar(true);
                    }
                  }}
                  className={classes.enter_button}
                >
                  บันทึกข้อมูล
                </Button>
                {/* <Button
                  className={classes.enter_temporary_button}
                  color="primary"
                  onClick={onTemporarySubmit}
                >
                  บันทึกข้อมูลชั่วคราว
                </Button> */}
              </Grid>
            </Paper>
          </Grid>
          <div>
            <Dialog open={submitDialog} onClose={onCloseSubmitDialog}>
              <DialogTitle>
                {props.language === "th"
                  ? "คุณต้องการส่งข้อมูลใช่หรือไม่"
                  : "lorem ipsum"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {props.language === "th"
                    ? "โปรดตรวจสอบข้อมูลให้แน่ชัดก่อนทำการส่ง"
                    : "lorem ipsum"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onCloseSubmitDialog}
                  className={classes.dialog_button_cancel}
                >
                  {props.language === "th" ? "ยกเลิก" : "Cancel"}
                </Button>
                <Button
                  onClick={onSubmitFirstVisit}
                  className={classes.dialog_button_send}
                >
                  {props.language === "th" ? "ยืนยัน" : "Send"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              คุณยังกรอกข้อมูลไม่ครบ
            </Alert>
          </Snackbar>
        </Grid>
      </div>
    </div>
  );

  return <div>{enter_firstvisit}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
  patient: state.patient,
});
export default connect(mapStateToProps, undefined)(EnterFirstVisit);
