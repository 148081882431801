export const convertObservationToModel = (FhirScreening) => {
  const observation = {
    waist: FhirScreening[0].resource.valueQuantity.value,
    weight: FhirScreening[1].resource.valueQuantity.value,
    bmi: FhirScreening[2].resource.valueQuantity.value,
    musclemass: FhirScreening[3].resource.valueQuantity.value,
    fatmass: FhirScreening[4].resource.valueQuantity.value,
    fbs: FhirScreening[5].resource.valueQuantity.value,
    ogtt: FhirScreening[6].resource.valueQuantity.value,
    cpeptide: FhirScreening[7].resource.valueQuantity.value,
    homair: FhirScreening[8].resource.valueQuantity.value,
    ilten: FhirScreening[9].resource.valueQuantity.value,
    height: FhirScreening[10].resource.valueQuantity.value,
    hba1c: FhirScreening[11].resource.valueQuantity.value,
    systolic: FhirScreening[12].resource.valueQuantity.value,
    diastolic: FhirScreening[13].resource.valueQuantity.value,
  };
  return observation;
};
