import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Checkbox,
  InputBase,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  Tabs,
  Tab,
  Button,
  FormControl,
  Typography,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import { lightBlue, red, teal, yellow, grey } from "@material-ui/core/colors";
import PatientButtonStatus from "../../../utility/components/appointment_status";
import male from "../../../assets/images/pic-icon-male.png";
import female from "../../../assets/images/pic-icon-female.png";
import style from "./style";
import MainMenu from "../main_menu";
import { convertPatientStatus } from "../../../utility/appointment";
import { ConvertToModel } from "../../../utility/patient";
import { getDiffDays } from "../../../utility/time";
import {
  PatientWaitForOpenAppointmentable,
  UpdatePatientSubStatus,
} from "../../../dataservice/patient/patient";
import { PATIENTSTATUS } from "../../../utility/const";

let OpenAppointmentable = (props) => {
  const columns = [
    { id: "firstName", label: "ชื่อ", minWidth: 50 },
    { id: "lastName", label: "นามสกุล", minWidth: 50 },
    { id: "gender", label: "เพศ", minWidth: 50 },
    { id: "group", label: "กลุ่ม", minWidth: 50 },
    { id: "status", label: "สถานะ", minWidth: 50 },
    { id: "phone", label: "ติดต่อ", minWidth: 50 },
    { id: "lastAppointment", label: "นัดหมายล่าสุด", minWidth: 50 },
  ];

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              color="primary"
            />
          </TableCell>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              style={{ minWidth: column.minWeight }}
              className={classes.table_head_cell}
              align="center"
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const classes = makeStyles(style)();
  const [index, setIndex] = useState(0);
  const [status, setStatus] = useState(undefined);
  const [patients, setPatients] = useState([]);
  const [showPatients, setShowPatients] = useState(patients);
  const [selected, setSelected] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [tableRowPerPage, setTableRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    async function fetchData() {
      await PatientWaitForOpenAppointmentable(props.user.location.id).then(
        (res) => {
          let patientObjects = res.data.entry.map((entry) =>
            ConvertToModel(entry.resource)
          );
          if (status) {
            patientObjects = patientObjects.filter(
              (patient) => patient.status === status
            );
          }
          setPatients(patientObjects);
          setShowPatients(patientObjects);
        }
      );
    }
    fetchData();
  }, [status, props.user.location.id]);

  const searchPatient = () => {
    let temp_data = [...patients];
    if (searchString !== "") {
      temp_data = temp_data.filter(
        (patient) =>
          patient.firstName.includes(searchString.trim()) ||
          patient.lastName.includes(searchString.trim()) ||
          patient.group.toString() === searchString
      );
    }
    setShowPatients(temp_data);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = showPatients.map((patient) => patient.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function onChangePage(e, newPage) {
    setTablePage(newPage);
  }
  function onChangeRowsPerPage(e) {
    setTableRowsPerPage(+e.target.value);
    setTablePage(0);
  }

  const covertStatusColor = (status) => {
    switch (status) {
      case 1:
        return teal;
      case 2:
        return teal;
      case 3:
        return lightBlue;
      case 4:
        return yellow;
      case 5:
        return red;
      default:
        return grey;
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  async function openAppointmentable() {
    const users = selected.map((user) => parseInt(user));
    const payload = {
      users: users,
      subStatus: 1,
    };
    const config = {
      headers: { Authorization: `Bearer ${props.user.access_token}` },
    };
    await UpdatePatientSubStatus(payload, config).then((res) => {
      setBackdrop(true);
      if (res.data.status === "success") {
        setOpen(true);
        setBackdrop(false);
        window.location.reload(false);
      } else {
        throw new Error("Something error");
      }
    });
  }

  const table = (
    <React.Fragment>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={showPatients.length}
          />
          <TableBody>
            {showPatients
              .slice(
                tablePage * tableRowPerPage,
                tablePage * tableRowPerPage + tableRowPerPage
              )
              .map((patient, index) => {
                const isItemSelected = isSelected(patient.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    onClick={(event) => handleClick(event, patient.id)}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    className={classes.table_row}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell
                      className={classes.table_cell}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {patient.firstName}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.lastName}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.gender === "male" ? (
                        <img src={male} alt="sex" className={classes.sex} />
                      ) : (
                        <img src={female} alt="sex" className={classes.sex} />
                      )}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.group}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      <PatientButtonStatus
                        className={classes.appointment_status_button}
                        label={convertPatientStatus(patient.status)}
                        statuscolor={covertStatusColor(patient.status)["A700"]}
                        backgroundstatuscolor={
                          covertStatusColor(patient.status)["A100"]
                        }
                        disableRipple
                      />
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.phone}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {getDiffDays(patient.lastAppointment)} วันก่อน
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );

  let open_appointmentable = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} style={{ padding: 16 }}>
          <Grid item xs={12} align="left">
            <Typography variant="h4">
              เปิดอนุญาตนัด ({props.user.location.name})
            </Typography>
            <Typography
              variant="h6"
              component={NavLink}
              to="/open_appointmentable_nonselectlocation"
              key="OpenAppointmentableNonSelectLocation"
              style={{ textDecorationLine: "none" }}
            >
              (สำหรับอาสาสมัครที่ยังไม่ได้เลือกศูนย์ตรวจ คลิกที่นี่)
            </Typography>
          </Grid>
          <Grid item xs={12} align="left">
            <Paper style={{ borderRadius: 24 }}>
              <Grid item xs={12} style={{ display: "flex", padding: 24 }}>
                <FormControl
                  style={{
                    flexGrow: 1,
                    marginRight: 40,
                    backgroundColor: "#eeeeee",
                    padding: 8,
                    borderRadius: 16,
                  }}
                >
                  <InputBase
                    placeholder="ค้นหารายชื่อ,กลุ่ม"
                    style={{ marginLeft: 16 }}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </FormControl>
                <Button
                  className={classes.search_button}
                  onClick={searchPatient}
                >
                  <Typography>ค้นหา</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <Tabs
                  value={index}
                  indicatorColor="primary"
                  onChange={(event, newIndex) => {
                    setIndex(newIndex);
                  }}
                  aria-label="simple tabs example"
                  style={{ flexGrow: 1 }}
                >
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="ทั้งหมด"
                    {...a11yProps(0)}
                    onClick={() => {
                      setStatus(undefined);
                    }}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="การอบรมครั้งแรก"
                    {...a11yProps(2)}
                    onClick={() => {
                      setStatus(PATIENTSTATUS.FIRSTVISIT);
                    }}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="ติดตามผลครั้งที่ 1"
                    {...a11yProps(2)}
                    onClick={() => {
                      setStatus(PATIENTSTATUS.FOLLOWUP1);
                    }}
                  />
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="ติดตามผลครั้งที่ 2"
                    {...a11yProps(2)}
                    onClick={() => {
                      setStatus(PATIENTSTATUS.FOLLOWUP2);
                    }}
                  />
                </Tabs>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={showPatients.length}
                  rowsPerPage={tableRowPerPage}
                  page={tablePage}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", padding: "0px 24px 24px 24px" }}
              >
                <Grid
                  container
                  justify="space-around"
                  style={{
                    padding: 24,
                    borderRadius: 16,
                    backgroundColor: "#eeeeee",
                    flexGrow: 1,
                  }}
                >
                  <Grid style={{ display: "flex" }}>
                    <Typography style={{ margin: "auto" }}>ทั้งหมด</Typography>
                    <Typography variant="h3" style={{ marginLeft: 24 }}>
                      {showPatients.length}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ padding: 24 }}>
                {table}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={selected.length === 0 ? true : false}
              onClick={() => openAppointmentable()}
              className={classes.enter_button}
            >
              เปิดอนุญาตนัด
            </Button>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            เปิดอนุญาตนัดสำเร็จ
          </Alert>
        </Snackbar>
      </div>
    </div>
  );

  return <div>{open_appointmentable}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
});

export default connect(mapStateToProps, undefined)(OpenAppointmentable);
