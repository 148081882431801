const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  body_header: {
    textAlign: "left",
  },
  table_head_cell: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    padding: theme.spacing(1),
  },
  table_row: {
    "&:hover": {
      backgroundColor: "#00e676",
      cursor: "pointer",
    },
  },
  table_cell: {
    padding: theme.spacing(0),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
  timeCell: {
    borderRadius: 20,
    borderColor: theme.palette.common.green,
    "&:hover": {
      backgroundColor: theme.palette.common.green,
      cursor: "normal",
    },
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  sort_color: {
    fill: theme.palette.primary.main,
  },
  sex: {
    width: 30,
    height: 30,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    padding: 1,
  },
  search_button: {
    width: 200,
    borderRadius: 16,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  search_name: {
    minWidth: 200,
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  search_group: {
    minWidth: 200,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  search_gender: {
    minWidth: 200,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  select_date: {
    minWidth: 200,
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  confirmDialog: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cancelDialog: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  enter_button: {
    width: "50%",
    minHeight: 56,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.main,
    },
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

export default styles;
