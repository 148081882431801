import { MPGroup } from "../route";
import axios from "axios";

export const MPGroupGetLabId = (payload) => {
    const url = MPGroup() + "/getlabid"
    return axios.post(url, payload)
}

export const MPGroupCheckSum = (payload) => {
    const url = MPGroup() + "/checksum"
    return axios.post(url, payload)
}

export const MPGroupAddDonor = (payload) => {
    const url = MPGroup() + "/adddonor"
    return axios.post(url, payload)
}