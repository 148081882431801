import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Button,
} from "@material-ui/core/";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import style from "./style";
import { setUser } from "../../redux/actions/user";
import { setPatient } from "../../redux/actions/patient";
import { setStatus } from "../../redux/actions/status";

let MainMenu = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { container } = props;
  const location = useLocation();
  const classes = makeStyles(style)();

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  useEffect(() => {
    async function updateSelectedIndex() {
      switch (location.pathname) {
        case "/home":
          setSelectedIndex(0);
          break;
        case "/select_screening":
          setSelectedIndex(1);
          break;
        case "/list_patient":
          setSelectedIndex(1);
          break;
        case "/history_taking":
          setSelectedIndex(1);
          break;
        case "/enter_followup":
          setSelectedIndex(1);
          break;
        case "/enter_success":
          setSelectedIndex(1);
          break;
        case "/create_firstvisit":
          setSelectedIndex(2);
          break;
        case "/cancel_firstvisit":
          setSelectedIndex(3);
          break;
        case "/create_availabledate":
          setSelectedIndex(4);
          break;
        case "/open_appointmentable_nonselectlocation":
          setSelectedIndex(5);
          break;
        case "/close_appointmentable_nonselectlocation":
          setSelectedIndex(6);
          break;
        case "/open_appointmentable":
          setSelectedIndex(7);
          break;
        case "/close_appointmentable":
          setSelectedIndex(8);
          break;
        case "/send_invitation":
          setSelectedIndex(9);
          break;

        default:
          break;
      }
    }
    updateSelectedIndex();
  }, [location.pathname]);

  const practitioner = (
    <div>
      <div className={classes.toolbar} />

      <List style={{ padding: 0 }}>
        <ListItem
          selected={selectedIndex === 0}
          button
          component={NavLink}
          to="/home"
          key="Home"
        >
          <ListItemIcon>
            <DashboardIcon
              color={selectedIndex === 0 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={props.language === "en" ? "Home" : "หน้าหลัก"}
          />
        </ListItem>
        <ListItem
          selected={selectedIndex === 1}
          button
          component={NavLink}
          to="/select_screening"
          key="Screening"
        >
          <ListItemIcon>
            <FolderOpenIcon
              color={selectedIndex === 1 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={props.language === "en" ? "Screening" : "บันทึกข้อมูล"}
          />
        </ListItem>
        {/* <ListItem
          selected={selectedIndex === 2}
          button
          component={NavLink}
          to="/create_firstvisit"
          key="CreateFirstVisit"
        >
          <ListItemIcon>
            <EventAvailableIcon
              color={selectedIndex === 2 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              props.language === "en"
                ? "CreateFirstVisit"
                : "สร้างนัด 1st Visit"
            }
          />
        </ListItem>
        <ListItem
          selected={selectedIndex === 3}
          button
          component={NavLink}
          to="/cancel_firstvisit"
          key="CancelFirstVisit"
        >
          <ListItemIcon>
            <EventBusyIcon
              color={selectedIndex === 3 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              props.language === "en" ? "CancelFirstVisit" : "ลบนัด 1st Visit"
            }
          />
        </ListItem> */}
        <ListItem
          selected={selectedIndex === 4}
          button
          component={NavLink}
          to="/create_availabledate"
          key="CreateAvailableDate"
        >
          <ListItemIcon>
            <EventIcon color={selectedIndex === 4 ? "primary" : "inherit"} />
          </ListItemIcon>
          <ListItemText
            primary={
              props.language === "en"
                ? "CreateAvailableDate"
                : "เพิ่มวันสำหรับนัดหมาย"
            }
          />
        </ListItem>
        {/* <ListItem
          selected={selectedIndex === 5}
          button
          component={NavLink}
          to="/open_appointmentable_nonselectlocation"
          key="OpenAppointmentableNonSelectLocation"
        >
          <ListItemIcon>
            <EventAvailableIcon
              color={selectedIndex === 5 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              props.language === "en"
                ? "OpenAppointmentableNonSelectLocation"
                : "เปิดอนุญาตนัดครั้งแรก"
            }
          />
        </ListItem>
        <ListItem
          selected={selectedIndex === 6}
          button
          component={NavLink}
          to="/close_appointmentable_nonselectlocation"
          key="CloseAppointmentableNonSelectLocation"
        >
          <ListItemIcon>
            <EventAvailableIcon
              color={selectedIndex === 6 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              props.language === "en"
                ? "CloseAppointmentableNonSelectLocation"
                : "ยกเลิกอนุญาตนัดครั้งแรก"
            }
          />
        </ListItem> 

        <ListItem
          selected={selectedIndex === 7}
          button
          component={NavLink}
          to="/open_appointmentable"
          key="OpenAppointmentable"
        >
          <ListItemIcon>
            <EventAvailableIcon
              color={selectedIndex === 7 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              props.language === "en" ? "OpenAppointmentable" : "เปิดอนุญาตนัด"
            }
          />
        </ListItem>
        <ListItem
          selected={selectedIndex === 8}
          button
          component={NavLink}
          to="/close_appointmentable"
          key="CloseAppointmentable"
        >
          <ListItemIcon>
            <EventBusyIcon
              color={selectedIndex === 8 ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              props.language === "en"
                ? "CloseAppointmentable"
                : "ยกเลิกอนุญาตนัด"
            }
          />
        </ListItem>
        */}
        <ListItem
          selected={selectedIndex === 9}
          button
          component={NavLink}
          to="/send_invitation"
          key="SendInvitation"
        >
          <ListItemIcon>
            <EmailIcon color={selectedIndex === 9 ? "primary" : "inherit"} />
          </ListItemIcon>
          <ListItemText
            primary={
              // props.language === "en" ? "SendInvitation" : "เชิญเป็นอาสาสมัคร"
              props.language === "en" ? "SendInvitation" : "รายชื่ออาสาสมัคร"
            }
          />
        </ListItem>
      </List>
    </div>
  );

  const doctor = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button component={NavLink} to="/record" key="History">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary={props.language === "en" ? "History" : "ประวัติการตรวจ"}
          />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  let main_menu = (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={1}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ flexGrow: 1, textAlign: "left" }}
          >
            {props.language === " en"
              ? "Thai Red Cross Society"
              : "Thai Red Cross Society"}
          </Typography>
          <div>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              component={NavLink}
              to="/"
              onClick={() => {
                props.setUser({
                  isAuthenticate: null,
                  email: null,
                  firstName: null,
                  lastName: null,
                  role: null,
                  access_token: null,
                  location: null,
                });
                props.setPatient({
                  id: null,
                  nationalID: null,
                  firstName: null,
                  lastName: null,
                  sex: null,
                  birthDate: null,
                  status: null,
                  wristBandId: null,
                  appointmentId: null,
                });
                props.setStatus(0);
                localStorage.clear();
              }}
            >
              <Typography color="primary">Logout</Typography>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {props.user.role === 2 ? practitioner : doctor}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            PaperProps={{ elevation: 8 }}
            open
          >
            {props.user.role === 2 ? practitioner : doctor}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );

  return <div>{main_menu}</div>;
};

MainMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

const mapStateToProps = (state) => ({
  language: state.language,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
  setPatient: (data) => dispatch(setPatient(data)),
  setStatus: (data) => dispatch(setStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
