const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  profile_header: {
    padding: theme.spacing(2),
    display: "flex"
  },
  profile_subheader: {
    display: "flex"
  },
  avatar: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(2)
  },
  sex: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1)
  },
  text_status: {
    textAlign: "left",
    paddingLeft: theme.spacing(2)
  },
  align_left: {
    textAlign: "left"
  },
  tabs: {
    flexGrow: 1
  },
  list_data: {
    display: "flex",
    padding: theme.spacing(2)
  },
  IL_spacing: {
    padding: theme.spacing(2)
  },
  IL_paper: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 150,
    borderRadius: "50%",
    margin: theme.spacing(2)
  },
  save_button: {
    width: 400,
    minHeight: 56,
    color: "white",
    marginBottom: theme.spacing(1),
    fontSize: theme.spacing(3)
  },
  tabicon_color: {
    color: "red"
  },
  stebContainer: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});

export default styles;
