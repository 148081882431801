export const dateTimeToStringTH = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const convertDateTimeToStringTH = (date) => {
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = date.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const getDiffDays = (date) => {
  const today = new Date();
  const focusDate = new Date(date);
  const diffTime = Math.abs(today - focusDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const InstanceDateParser = (dateTime) => {
  let parsedDateTime = dateTime.split("T");
  let date = parsedDateTime[0].split("-");
  let parsedTime = parsedDateTime[1].split("+");
  let time = parsedTime[0].split(":");
  return new Date(
    parseInt(date[0]),
    parseInt(date[1]) - 1,
    parseInt(date[2]),
    parseInt(time[0]),
    parseInt(time[1])
  );
};

export const JSInstantDateParser = (
  date,
  toSecond = false,
  toMillisecond = false,
  plusSeven = false
) => {
  const year = date.getFullYear();
  const month = singleUnitConverter(date.getMonth());
  const day = singleUnitConverter(date.getDate());
  const hour = plusSeven
    ? singleUnitConverter(date.getHours() + 7)
    : singleUnitConverter(date.getHours());
  const minute = singleUnitConverter(date.getMinutes());
  const second = singleUnitConverter(date.getSeconds());
  const millisecond = milliSecondConverter(date.getMilliseconds());
  let dateString = year + "-" + month + "-" + day + "T" + hour + ":" + minute;
  if (toSecond) {
    dateString += ":" + second;
  }
  if (toMillisecond) {
    dateString += "." + millisecond;
  }
  return dateString + "+07:00";
};

export const singleUnitConverter = (unit) => {
  if (unit < 10) {
    return "0" + unit;
  }
  return unit;
};
const milliSecondConverter = (ms) => {
  if (ms < 10) {
    return "00" + ms;
  } else if (ms < 100) {
    return "0" + ms;
  }
  return ms;
};
