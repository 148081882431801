import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AuthRoute = (props) => {
  let time = new Date().getTime() / 1000
  if (!props.user.isAuthenticate || (jwt_decode(props.user.access_token).exp <= time)) {
    return <Redirect to="/" />;
  }
  return <Route {...props} />;
};
const mapStateToProps = (state) => ({
  language: state.language,
  user: state.user,
});

export default connect(mapStateToProps, undefined)(AuthRoute);
