import { combineReducers } from "redux";
import languageReducer from "./language";
import userReducer from "./user";
import statusReducer from "./status"
import patientReducer from "./patient";

const reducer = combineReducers({
  user: userReducer,
  language: languageReducer,
  patient: patientReducer,
  status: statusReducer,
});

export default reducer;
