import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import style from "./style";
import MainMenu from "../main_menu";
import { convertDateTimeToStringTH } from "../../../utility/time";
import {
  GetAvailableDates,
  CreateAvailableDates,
} from "../../../dataservice/appointment/appointment";

let CreateAvailableDate = (props) => {
  const classes = makeStyles(style)();
  const [dates, setDates] = useState([]);
  const [datesString, setDatesString] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: { Authorization: `Bearer ${props.user.access_token}` },
      };
      await GetAvailableDates(config).then((res) => {
        setDatesString(
          res.data.data.availableDates.map(
            (availableDate) => availableDate.date
          )
        );
        let availableDates = res.data.data.availableDates.map(
          (availableDate) => new Date(availableDate.date)
        );
        setDates(availableDates);
      });
    }
    fetchData();
  }, [props.user.location.id, props.user.access_token]);

  const createAvailableDates = async () => {
    let expiredDate = new Date(convertDateTimeToStringTH(selectedDate));
    //expiredDate.setDate(expiredDate.getDate() - 3);
    expiredDate.setDate(expiredDate.getDate());
    const payload = {
      availableDates: [
        {
          date: convertDateTimeToStringTH(selectedDate),
          appointmentType: "Screening",
          location: props.user.location.id,
          expiredAt: convertDateTimeToStringTH(expiredDate),
          start: "07:00:00",
          end: "10:00:00",
        },
      ],
    };
    const config = {
      headers: { Authorization: `Bearer ${props.user.access_token}` },
    };
    await CreateAvailableDates(payload, config).then((res) => {
      setBackdrop(true);
      if (res.data.status === "success") {
        setOpen(true);
        setBackdrop(false);
        window.location.reload(false);
      } else {
        throw new Error("Something error");
      }
    });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let create_availabledate = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} style={{ padding: 16 }}>
          <Grid item xs={12} align="left">
            <Typography variant="h4">
              เพิ่มวันสำหรับนัดหมาย ({props.user.location.name})
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant="h6" style={{ marginBottom: 16 }}>
              ตารางวันที่สามารถทำการนัดหมายได้ในระบบ ({props.user.location.name}
              )
            </Typography>
            <Calendar
              className="red"
              multiple
              value={dates}
              mapDays={({ date, today, isSameDate }) => {
                let props = { style: {} };
                if (isSameDate(date, today)) {
                  props.style.backgroundColor = "white";
                  props.style.color = "black";
                  if (
                    datesString.includes(
                      `${today.year}-${today.month.number}-${today.day}`
                    )
                  ) {
                    props.style.backgroundColor = "red";
                    props.style.color = "white";
                  }
                }

                return props;
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12} style={{ margin: "auto" }}>
            <Typography variant="h6">เลือกวันนัดหมายที่ต้องการเพิ่ม</Typography>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.select_date}
                id="date-picker-dialog"
                label="เลือกวันนัดหมาย"
                format="dd/MM/yyyy"
                value={selectedDate}
                onChange={setSelectedDate}
                disablePast={true}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              onClick={() => createAvailableDates()}
              className={classes.enter_button}
            >
              เพิ่มวันสำหรับนัดหมาย
            </Button>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            เพิ่มวันสำหรับนัดหมายสำเร็จ
          </Alert>
        </Snackbar>
      </div>
    </div>
  );

  return <div>{create_availabledate}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
});

export default connect(mapStateToProps, undefined)(CreateAvailableDate);
