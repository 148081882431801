import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core/";
import { setStatus } from "../../../../redux/actions/status";

import screening_on from "../../../../assets/images/button-screen-on.png";
import firsVisit_on from "../../../../assets/images/button-workshop-on.png";
import followUp1_on from "../../../../assets/images/button-report-1-on.png";
import followUp2_on from "../../../../assets/images/button-report-2-on.png";

import screening_hover from "../../../../assets/images/button-screen-hover.png";
import firsVisit_hover from "../../../../assets/images/button-workshop-hover.png";
import followUp1_hover from "../../../../assets/images/button-report-1-hover.png";
import followUp2_hover from "../../../../assets/images/button-report-2-hover.png";

import style from "./style";
import MainMenu from "../../main_menu";

let SelectScreening = (props) => {
  const classes = makeStyles(style)();

  let history = useHistory();

  function onClickScreening(e) {
    props.setStatus(e);
    history.push("/list_patient");
  }

  let select_screening = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12} style={{ marginTop: "4em" }}>
            <Typography variant="h4">
              {props.language === "en" ? "Lorem Ipsum" : "เลือกขั้นตอนการวิจัย"}
            </Typography>
          </Grid>
          <Grid container justify="space-evenly">
            <Grid item>
              <Button disableRipple className={classes.stebContainer}>
                <img
                  alt="screening"
                  onClick={(e) => onClickScreening(1)}
                  onMouseOver={(e) => (e.currentTarget.src = screening_hover)}
                  onMouseOut={(e) => (e.currentTarget.src = screening_on)}
                  src={screening_on}
                />
              </Button>
              <Typography variant="h6">
                {props.language === "en" ? "Screening" : "การคัดกรอง"}
              </Typography>
            </Grid>
            <Grid item>
              <Button disableRipple className={classes.stebContainer}>
                <img
                  alt="firstvisit"
                  onClick={(e) => onClickScreening(3)}
                  onMouseOver={(e) => (e.currentTarget.src = firsVisit_hover)}
                  onMouseOut={(e) => (e.currentTarget.src = firsVisit_on)}
                  src={firsVisit_on}
                />
              </Button>
              <Typography variant="h6">
                {props.language === "en" ? "1st Visit" : "การอบรมครั้งแรก"}
              </Typography>
            </Grid>
            <Grid item>
              <Button disableRipple className={classes.stebContainer}>
                <img
                  alt="followup1"
                  onClick={(e) => onClickScreening(4)}
                  onMouseOver={(e) => (e.currentTarget.src = followUp1_hover)}
                  onMouseOut={(e) => (e.currentTarget.src = followUp1_on)}
                  src={followUp1_on}
                />
              </Button>
              <Typography variant="h6">
                {props.language === "en"
                  ? "1st Followup"
                  : "ติดตามผลครั้งที่ 1"}
              </Typography>
            </Grid>
            <Grid item>
              <Button disableRipple className={classes.stebContainer}>
                <img
                  alt="followup2"
                  onClick={(e) => onClickScreening(5)}
                  onMouseOver={(e) => (e.currentTarget.src = followUp2_hover)}
                  onMouseOut={(e) => (e.currentTarget.src = followUp2_on)}
                  src={followUp2_on}
                />
              </Button>
              <Typography variant="h6">
                {props.language === "en"
                  ? "2nd Followup"
                  : "ติดตามผลครั้งที่ 2"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
  return <div>{select_screening}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
  status: state.status,
});
const mapDispatchToProps = (dispatch) => ({
  setStatus: (data) => dispatch(setStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectScreening);
