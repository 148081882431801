const patient = {
  id: undefined,
  nationalID: undefined,
  firstName: undefined,
  lastName: undefined,
  sex: undefined,
  birthDate: undefined,
  status: undefined,
  group: undefined,
  wristBandId: undefined,
  appointmentId: undefined,
  appointmentStatus: undefined,
};
export const patientReducer = (state = patient, action) => {
  switch (action.type) {
    case "SET_PATIENT":
      state.id = action.data.id;
      state.nationalID = action.data.nationalID;
      state.firstName = action.data.firstName;
      state.lastName = action.data.lastName;
      state.sex = action.data.sex;
      state.birthDate = action.data.birthDate;
      state.status = action.data.status;
      state.group = action.data.group;
      state.wristBandId = action.data.wristBandId;
      state.appointmentId = action.data.appointmentId;
      state.appointmentStatus = action.data.appointmentStatus;
      return state;
    default:
      return state;
  }
};
export default patientReducer;
