import { Observation } from "../route";
import axios from "axios";

export const ObservationPostScreening = (payload) => {
    const url = Observation();
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    return axios.post(url, payload, config)
}