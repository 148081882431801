import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Checkbox,
  InputBase,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  Button,
  TextField,
  FormControl,
  Typography,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core/";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MuiAlert from "@material-ui/lab/Alert";
import { lightBlue, red, teal, yellow, grey } from "@material-ui/core/colors";
import PatientButtonStatus from "../../../utility/components/appointment_status";
import DateFnsUtils from "@date-io/date-fns";
import male from "../../../assets/images/pic-icon-male.png";
import female from "../../../assets/images/pic-icon-female.png";
import style from "./style";
import MainMenu from "../main_menu";
import { convertPatientStatus } from "../../../utility/appointment";
import { ConvertToModel } from "../../../utility/patient";
import { dateTimeToStringTH } from "../../../utility/time";
import { PatientWaitForCreateFirstVisit } from "../../../dataservice/patient/patient";
import { AppointmentCreateFirstVisit } from "../../../dataservice/appointment/appointment";

let CreateFirstVisit = (props) => {
  const columns = [
    { id: "firstName", label: "ชื่อ", minWidth: 50 },
    { id: "lastName", label: "นามสกุล", minWidth: 50 },
    { id: "gender", label: "เพศ", minWidth: 50 },
    { id: "group", label: "กลุ่ม", minWidth: 50 },
    { id: "status", label: "สถานะ", minWidth: 50 },
    { id: "phone", label: "ติดต่อ", minWidth: 50 },
  ];

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              color="primary"
            />
          </TableCell>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              style={{ minWidth: column.minWeight }}
              className={classes.table_head_cell}
              align="center"
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const classes = makeStyles(style)();
  const [patients, setPatients] = useState([]);
  const [showPatients, setShowPatients] = useState(patients);
  const [selected, setSelected] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [tableRowPerPage, setTableRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [selectedDate, setSelectedDate] = useState(dateTimeToStringTH());
  const [start, setStart] = useState("09:30");
  const [end, setEnd] = useState("10:30");
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await PatientWaitForCreateFirstVisit(props.user.location.id).then(
        (res) => {
          let patientObjects = res.data.entry.map((entry) =>
            ConvertToModel(entry.resource)
          );
          setPatients(patientObjects);
          setShowPatients(patientObjects);
        }
      );
    }
    fetchData();
  }, [props.user.location.id]);

  const searchPatient = () => {
    let temp_data = [...patients];
    if (searchString !== "") {
      temp_data = temp_data.filter(
        (patient) =>
          patient.firstName.includes(searchString.trim()) ||
          patient.lastName.includes(searchString.trim()) ||
          patient.group.toString() === searchString
      );
    }
    setShowPatients(temp_data);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = showPatients.map((patient) => patient.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    // console.log(newSelected);
  };

  function onChangePage(e, newPage) {
    setTablePage(newPage);
  }
  function onChangeRowsPerPage(e) {
    setTableRowsPerPage(+e.target.value);
    setTablePage(0);
  }

  const covertStatusColor = (status) => {
    switch (status) {
      case 1:
        return teal;
      case 2:
        return teal;
      case 3:
        return lightBlue;
      case 4:
        return yellow;
      case 5:
        return red;
      default:
        return grey;
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  async function createAppointment() {
    const users = selected;
    users.forEach((user, index) => (users[index] = parseInt(user)));
    const payload = {
      users: users,
      appointmentModel: {
        date: selectedDate,
        start: start,
        end: end,
        location: props.user.location,
      },
    };
    await AppointmentCreateFirstVisit(payload).then(async (res) => {
      setBackdrop(true);
      setSelected([]);
      if (res.status === 200) {
        setOpen(true);
        setBackdrop(false);
        // await fetchData();
      }
    });
  }

  const table = (
    <React.Fragment>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={showPatients.length}
          />
          <TableBody>
            {showPatients
              .slice(
                tablePage * tableRowPerPage,
                tablePage * tableRowPerPage + tableRowPerPage
              )
              .map((patient, index) => {
                const isItemSelected = isSelected(patient.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    onClick={(event) => handleClick(event, patient.id)}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    className={classes.table_row}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell
                      className={classes.table_cell}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {patient.firstName}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.lastName}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.gender === "male" ? (
                        <img src={male} alt="sex" className={classes.sex} />
                      ) : (
                        <img src={female} alt="sex" className={classes.sex} />
                      )}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.group}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      <PatientButtonStatus
                        className={classes.appointment_status_button}
                        label={convertPatientStatus(patient.status)}
                        statuscolor={covertStatusColor(patient.status)["A700"]}
                        backgroundstatuscolor={
                          covertStatusColor(patient.status)["A100"]
                        }
                        disableRipple
                      />
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.phone}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );

  let create_appointment = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} style={{ padding: 16 }}>
          <Grid item xs={12} align="left">
            <Typography variant="h4">
              สร้างนัด 1st Visit ({props.user.location.name})
            </Typography>
          </Grid>
          <Grid item xs={12} align="left">
            <Paper style={{ borderRadius: 24 }}>
              <Grid item xs={12} style={{ display: "flex", padding: 24 }}>
                <FormControl
                  style={{
                    flexGrow: 1,
                    marginRight: 40,
                    backgroundColor: "#eeeeee",
                    padding: 8,
                    borderRadius: 16,
                  }}
                >
                  <InputBase
                    placeholder="ค้นหารายชื่อ,กลุ่ม"
                    style={{ marginLeft: 16 }}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </FormControl>
                <Button
                  className={classes.search_button}
                  onClick={searchPatient}
                >
                  <Typography>ค้นหา</Typography>
                </Button>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={showPatients.length}
                  rowsPerPage={tableRowPerPage}
                  page={tablePage}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 24 }}>
                {table}
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.select_date}
                id="date-picker-dialog"
                label="เลือกวันนัดหมาย"
                format="dd/MM/yyyy"
                value={selectedDate}
                onChange={setSelectedDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              id="startTime"
              label="เวลาเริ่ม"
              type="time"
              value={start}
              onChange={(e) => {
                setStart(e.target.value);
              }}
              className={classes.select_date}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              id="endTime"
              label="เวลาจบ"
              type="time"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              className={classes.select_date}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={selected.length === 0 ? true : false}
              onClick={() => createAppointment()}
              className={classes.enter_button}
            >
              สร้างนัด
            </Button>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            สร้างนัดสำเร็จ
          </Alert>
        </Snackbar>
      </div>
    </div>
  );

  return <div>{create_appointment}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
});

export default connect(mapStateToProps, undefined)(CreateFirstVisit);
