import React from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import AuthRoute from "./router/authRoute";
import Login from "./pages/login/login";
import Home from "./pages/main_menu/home/home";
import SelectScreening from "./pages/main_menu/patient/select_screening/select_screening";
import ListPatient from "./pages/main_menu/patient/list_patient/list_patient";
import HistoryTaking from "./pages/main_menu/patient/history_taking/history_taking";
import EnterScreening from "./pages/main_menu/patient/enter_followup/enter_screening";
import EnterFirstVisit from "./pages/main_menu/patient/enter_followup/enter_firstvisit";
import EnterFollowup from "./pages/main_menu/patient/enter_followup/enter_followup";
import EnterSuccess from "./pages/main_menu/patient/enter_success/enter_success";
import Record from "./pages/main_menu/record/record";
import RecordPatient from "./pages/main_menu/record/record_patient/record_patient";
import CreateFirstVisit from "./pages/main_menu/appointment/create_firstvisit";
import CancelFirstVisit from "./pages/main_menu/appointment/cancel_firstvisit";
import CreateAvailableDate from "./pages/main_menu/appointment/create_availabledate";
import OpenAppointmentableNonSelectLocation from "./pages/main_menu/appointment/open_appointmentable_nonselectlocation";
import CloseAppointmentableNonSelectLocation from "./pages/main_menu/appointment/close_apoointmentable_nonselectlocation";
import OpenAppointmentable from "./pages/main_menu/appointment/open_appointmentable";
import CloseAppointmentable from "./pages/main_menu/appointment/close_appointmentable";
import SendInvitation from "./pages/main_menu/volunteer/send_invitation";
import store from "./redux/store/store";
import theme from "./theme/theme";
import "./App.css";

export default function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route path="/" component={Login} exact={true} />
            <AuthRoute path="/home" component={Home} exact={true} />
            <AuthRoute
              path="/select_screening"
              component={SelectScreening}
              exact={true}
            />
            <AuthRoute
              path="/list_patient"
              component={ListPatient}
              exact={true}
            />
            <AuthRoute
              path="/history_taking"
              component={HistoryTaking}
              exact={true}
            />
            <AuthRoute
              path="/enter_screening"
              component={EnterScreening}
              exact={true}
            />
            <AuthRoute
              path="/enter_firstvisit"
              component={EnterFirstVisit}
              exact={true}
            />
            <AuthRoute
              path="/enter_followup"
              component={EnterFollowup}
              exact={true}
            />
            <AuthRoute
              path="/enter_success"
              component={EnterSuccess}
              exact={true}
            />
            <AuthRoute
              path="/create_firstvisit"
              component={CreateFirstVisit}
              exact={true}
            />
            <AuthRoute
              path="/cancel_firstvisit"
              component={CancelFirstVisit}
              exact={true}
            />
            <AuthRoute
              path="/create_availabledate"
              component={CreateAvailableDate}
              exact={true}
            />
            <AuthRoute
              path="/open_appointmentable_nonselectlocation"
              component={OpenAppointmentableNonSelectLocation}
              exact={true}
            />
            <AuthRoute
              path="/close_appointmentable_nonselectlocation"
              component={CloseAppointmentableNonSelectLocation}
              exact={true}
            />
            <AuthRoute
              path="/open_appointmentable"
              component={OpenAppointmentable}
              exact={true}
            />
            <AuthRoute
              path="/close_appointmentable"
              component={CloseAppointmentable}
              exact={true}
            />
            <AuthRoute
              path="/send_invitation"
              component={SendInvitation}
              exact={true}
            />
            <AuthRoute path="/record" component={Record} exact={true} />
            <AuthRoute
              path="/record_patient"
              component={RecordPatient}
              exact={true}
            />
          </Switch>
        </MuiThemeProvider>
      </div>
    </Provider>
  );
}
