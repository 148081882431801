import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Typography,
  Button,
  FormControl,
  InputBase,
} from "@material-ui/core/";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { teal, grey } from "@material-ui/core/colors";
import male from "../../../../assets/images/pic-icon-male.png";
import female from "../../../../assets/images/pic-icon-female.png";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import style from "./style";
import MainMenu from "../../main_menu";
import { setPatient } from "../../../../redux/actions/patient";
import { setStatus } from "../../../../redux/actions/status";
import { ConvertToModel } from "../../../../utility/appointment";
import { convertDateTimeToStringTH } from "../../../../utility/time";
import { AppointmentGetByDate } from "../../../../dataservice/appointment/appointment";
import {
  convertPatientStatus,
  convertAppoientmentStatus,
} from "../../../../utility/appointment";
import {
  PATIENTSTATUS,
  APPOINTMENTTYPE,
  APPOINTMENTSTATUS,
} from "../../../../utility/const";

let ListPatient = (props) => {
  const [date, setDate] = useState(new Date());
  const [tablePage, setTablePage] = useState(0);
  const [tableRowPerPage, setTableRowsPerPage] = useState(10);
  const [appointments, setAppointments] = useState([]);
  const [showAppointments, setShowAppointments] = useState([]);
  const [showArrivedAppointments, setShowArrivedAppointments] = useState([]);
  const [numberOfAppointment, setNumberOfAppointment] = useState(0);
  const [numberOfAppointmentFulfilled, setNumberOfAppointmentFulfilled] =
    useState(0);
  const [searchString, setSearchString] = useState("");
  const appointment_columns = [
    {
      id: "date",
      label: "วันที่",
      minWidth: 170,
    },
    {
      id: "firstName",
      label: "ชื่อ",
      minWidth: 170,
    },
    {
      id: "lastName",
      label: "นามสกุล",
      minWidth: 170,
    },
    {
      id: "sex",
      label: "เพศ",
      minWidth: 170,
    },
    {
      id: "group",
      label: "กลุ่ม",
      minWidth: 170,
    },
    {
      id: "phone",
      label: "ติดต่อ",
      minWidth: 170,
    },
    {
      id: "status",
      label: "สถานะ",
      minWidth: 170,
    },
  ];
  const classes = makeStyles(style)();
  let history = useHistory();

  useEffect(() => {
    async function fetchData() {
      let appointmentFulfilled = 0;
      await AppointmentGetByDate(
        props.user.location.id,
        convertDateTimeToStringTH(date)
      ).then((res) => {
        let appointmentObjects = res.data.entry.map((entry) =>
          ConvertToModel(entry.resource)
        );
        if (props.status !== 0) {
          switch (props.status) {
            case 1:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.SCREENING
              );
              break;
            case 3:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.FIRSTVISIT
              );
              break;
            case 4:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.FOLLOWUP1
              );
              break;
            case 5:
              appointmentObjects = appointmentObjects.filter(
                (appointment) => appointment.type === APPOINTMENTTYPE.FOLLOWUP2
              );
              break;
            default:
              break;
          }
        }
        appointmentObjects.forEach((appointment) => {
          if (appointment.status === APPOINTMENTSTATUS.fulfilled) {
            appointmentFulfilled += 1;
          }
        });
        setShowArrivedAppointments(
          appointmentObjects.filter(
            (appointment) => appointment.status === APPOINTMENTSTATUS.arrived
          )
        );
        setNumberOfAppointment(appointmentObjects.length);
        setNumberOfAppointmentFulfilled(appointmentFulfilled);
        setAppointments(appointmentObjects);
        setShowAppointments(appointmentObjects);
      });
    }
    fetchData();
  }, [props.status, date, props.user.location.id]);

  const searchAppointment = () => {
    let temp_data = [...appointments];
    if (searchString !== "") {
      temp_data = temp_data.filter(
        (appointment) =>
          appointment.firstName.includes(searchString.trim()) ||
          appointment.lastName.includes(searchString.trim()) ||
          appointment.group.toString() === searchString
      );
    }
    setShowAppointments(temp_data);
  };

  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setTableRowsPerPage(parseInt(event.target.value, 10));
    setTablePage(0);
  };

  const switchForm = (patientStatus, appointmentType) => {
    switch (patientStatus) {
      case PATIENTSTATUS.HIDDENSTATUS:
        history.push("/history_taking");
        break;
      default:
        switch (appointmentType) {
          case APPOINTMENTTYPE.SCREENING:
            history.push("/enter_screening");
            break;
          case APPOINTMENTTYPE.FIRSTVISIT:
            history.push("/enter_firstvisit");
            break;
          case APPOINTMENTTYPE.FOLLOWUP1:
            history.push("/enter_followup");
            break;
          case APPOINTMENTTYPE.FOLLOWUP2:
            history.push("/enter_followup");
            break;
          default:
            break;
        }
        break;
    }
  };

  const table = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {appointment_columns.map((column) => (
              <TableCell key={column.id} className={classes.table_cell}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {showAppointments.map((appointment, index) => (
            <TableRow
              hover={appointment.status !== APPOINTMENTSTATUS.fulfilled}
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setPatient({
                  id: appointment.patientId,
                  nationalID: appointment.nationalID,
                  firstName: appointment.firstName,
                  lastName: appointment.lastName,
                  sex: appointment.gender,
                  group: appointment.group,
                  birthDate: appointment.birthDate,
                  wristBandId: appointment.wristBandId,
                  status: appointment.patientStatus,
                  appointmentId: appointment.appointmentId,
                  appointmentStatus: appointment.status,
                });
                switchForm(appointment.patientStatus, appointment.type);
              }}
            >
              <TableCell className={classes.table_cell}>
                {appointment.date}
              </TableCell>
              <TableCell className={classes.table_cell}>
                {appointment.firstName}
              </TableCell>
              <TableCell className={classes.table_cell}>
                {appointment.lastName}
              </TableCell>
              <TableCell className={classes.table_cell}>
                {appointment.gender === 1 ? (
                  <img src={male} alt="sex" className={classes.sex} />
                ) : (
                  <img src={female} alt="sex" className={classes.sex} />
                )}
              </TableCell>
              <TableCell className={classes.table_cell}>
                {appointment.group}
              </TableCell>
              <TableCell className={classes.table_cell}>
                {appointment.phone}
              </TableCell>
              <TableCell className={classes.table_cell}>
                <div
                  style={{
                    color:
                      appointment.status === APPOINTMENTSTATUS.fulfilled
                        ? teal["A700"]
                        : grey,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CheckCircleIcon
                    style={{
                      display:
                        appointment.status === APPOINTMENTSTATUS.fulfilled
                          ? "block"
                          : "none",
                    }}
                  />
                  <Typography>
                    {convertAppoientmentStatus(appointment.status)}
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  let list_patient = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} style={{ padding: 16 }}>
          <Grid item xs={9} align="left">
            <div>
              <Button
                disableRipple
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  props.setStatus(0);
                  history.push("/select_screening");
                }}
              >
                <ArrowBackIosIcon />
                <Typography>บันทึกข้อมูล</Typography>
              </Button>
            </div>
          </Grid>
          <Grid item xs={9} align="left" style={{ display: "flex" }}>
            <Typography variant="h4" style={{ flexGrow: 1 }}>
              {convertPatientStatus(props.status)} [{numberOfAppointment} ราย]
            </Typography>
            <Typography variant="h6" style={{ margin: "auto" }}>
              แสดง: วันนี้
            </Typography>
          </Grid>
          <Grid item xs={9} align="left">
            <Paper
              elevation={2}
              style={{
                borderRadius: 24,
              }}
            >
              <Grid item xs={12} style={{ display: "flex", padding: 24 }}>
                <FormControl
                  style={{
                    flexGrow: 1,
                    marginRight: 40,
                    backgroundColor: "#eeeeee",
                    padding: 8,
                    borderRadius: 16,
                  }}
                >
                  <InputBase
                    placeholder="ค้นหารายชื่อ,กลุ่ม"
                    style={{ marginLeft: 16 }}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </FormControl>
                <Button
                  className={classes.search_button}
                  onClick={searchAppointment}
                >
                  <Typography>ค้นหา</Typography>
                </Button>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={showAppointments.length}
                  rowsPerPage={tableRowPerPage}
                  page={tablePage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 24 }}>
                {table}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2} container direction="column">
            <Grid>
              <Paper
                style={{ padding: "32px", borderRadius: 24, marginBottom: 32 }}
              >
                <Grid container justify="space-around">
                  <Grid>
                    <Typography style={{ marginBottom: 16 }}>
                      ทั้งหมด
                    </Typography>
                    <Typography variant="h4">{numberOfAppointment}</Typography>
                  </Grid>
                  <Grid>
                    <Typography style={{ marginBottom: 16 }}>
                      เสร็จแล้ว
                    </Typography>
                    <Typography variant="h4">
                      {numberOfAppointmentFulfilled}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography style={{ marginBottom: 16 }}>
                      คงเหลือ
                    </Typography>
                    <Typography variant="h4">
                      {numberOfAppointment - numberOfAppointmentFulfilled}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid style={{ marginBottom: 16 }}>
              <Paper
                style={{
                  padding: 16,
                  borderRadius: 24,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    disableToolbar
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={setDate}
                  />
                </MuiPickersUtilsProvider>
              </Paper>
            </Grid>
            <Grid>
              <Paper style={{ padding: "32px", borderRadius: 24 }}>
                <Grid align="center">
                  <Typography variant={"h6"} style={{ marginBottom: 16 }}>
                    รายชื่อ - บันทึกชั่วคราว
                  </Typography>
                  {showArrivedAppointments.map((appointment, index) => (
                    <Grid item xs={12} key={index}>
                      <Button
                        disableRipple
                        className={classes.list_temporary}
                        onClick={() => {
                          props.setPatient({
                            id: appointment.patientId,
                            nationalID: appointment.nationalID,
                            firstName: appointment.firstName,
                            lastName: appointment.lastName,
                            sex: appointment.gender,
                            group: appointment.group,
                            birthDate: appointment.birthDate,
                            wristBandId: appointment.wristBandId,
                            status: appointment.patientStatus,
                            appointmentId: appointment.appointmentId,
                            appointmentStatus: appointment.status,
                          });
                          switchForm(
                            appointment.patientStatus,
                            appointment.type
                          );
                        }}
                      >
                        {`${appointment.firstName} 
                          ${appointment.lastName}`}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return <div>{list_patient}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
  patient: state.patient,
  status: state.status,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (data) => dispatch(setStatus(data)),
  setPatient: (data) => dispatch(setPatient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPatient);
