const user = {
  email: undefined,
  isAuthenticate: false,
  firstName: undefined,
  lastName: undefined,
  role: undefined,
  access_token: undefined,
  location: {
    id: undefined,
    name: undefined,
    capactiry: undefined,
    address: undefined,
    contact: undefined,
    siteCode: undefined,
    checkSum: undefined,
  },
};
export const userReducer = (state = user, action) => {
  switch (action.type) {
    case "SET_USER":
      state.email = action.data.email;
      state.isAuthenticate = action.data.isAuthenticate;
      state.firstName = action.data.firstName;
      state.lastName = action.data.lastName;
      state.role = action.data.role;
      state.access_token = action.data.access_token;
      state.location = action.data.location;
      return state;
    default:
      return state;
  }
};
export default userReducer;
