import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Tabs, Tab, Typography } from "@material-ui/core/";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import maleAvatar from "../../../../assets/images/pic-man-profile.png";
import femaleAvatar from "../../../../assets/images/pic-woman-profile.png";
import axios from "axios";
import { BACKENDURL } from "../../../../utility/config"
import style from "./style";
import MainMenu from "../../main_menu";
import { setPatient } from "../../../../redux/actions/patient";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

let RecordPatient = (props) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await axios.get(
        BACKENDURL + "/patient/" +
        props.patient.id +
        "/observation"
      ).then((res) => {
        if (rows.length === 0) {
          console.log(res.data.entry);
          setRows(res.data.entry);
        }
      })
    }
    fetchData();
  }, [rows.length, props.patient.id]);

  const classes = makeStyles(style)();

  const [tab, setTab] = useState(props.patient.status - 1);
  const screening_tabs = [
    {
      label: "Pre-Screening",
      id: "1",
    },
    {
      label: "Screening",
      id: "2",
    },
    {
      label: "1st Visit",
      id: "3",
    },
    {
      label: "1st Followup",
      id: "4",
    },
    {
      label: "2nd Followup",
      id: "5",
    },
  ];

  function handleTab(event, newValue) {
    setTab(newValue);
  }

  let record_patient = (
    <div>
      <MainMenu />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={0} className={classes.root}>
          <Grid item sm={12} xs={12}>
            <Paper className={classes.profile_header}>
              <img
                src={props.patient.sex === 1 ? maleAvatar : femaleAvatar}
                alt="avatar"
                className={classes.avatar}
              />
              <div style={{ alignItems: "center" }}>
                <div>
                  <Typography variant="h5">
                    หมายเลขอาสาสมัคร : {props.patient.id}
                  </Typography>
                </div>
                <div className={classes.profile_subheader}>
                  <Typography variant="h6">
                    วันเกิด : {props.patient.birthDate}
                  </Typography>
                </div>
              </div>
            </Paper>
            <Paper elevation={2} className={classes.paper_detail}>
              <div>
                <Paper square className={classes.tabs}>
                  <Tabs
                    value={tab}
                    onChange={handleTab}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                  >
                    {screening_tabs.map((tab) => (
                      <Tab
                        key={tab.label}
                        disabled={props.patient.status < parseInt(tab.id)}
                        label={tab.label}
                        icon={
                          props.patient.status >= parseInt(tab.id) ? (
                            <CheckCircleIcon color="primary" />
                          ) : (
                              <RadioButtonUncheckedIcon />
                            )
                        }
                      />
                    ))}
                  </Tabs>
                  <TabPanel value={tab} index={0}>
                    <Typography variant="h6">
                      {props.language === "en"
                        ? "Lorem Ipsum"
                        : "ผ่านการตรวจก่อนการคัดกรอง"}
                    </Typography>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    {!rows[0] ? (
                      <Typography variant="h6">
                        {props.language === "en"
                          ? "Lorem Ipsum"
                          : "ไม่มีข้อมูลการคัดกรอง"}
                      </Typography>
                    ) : (
                        <div>
                          <Typography variant="h6">
                            {props.language === "en"
                              ? "Lorem Ipsum"
                              : "มีข้อมูลการคัดกรอง"}
                          </Typography>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "waist" : "รอบเอว"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[0].resource.valueQuantity.value +
                                " " +
                                rows[0].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "weight" : "น้ำหนัก"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[1].resource.valueQuantity.value +
                                " " +
                                rows[1].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "BMI" : "BMI"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[2].resource.valueQuantity.value +
                                " " +
                                rows[2].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Muscle Mass"
                                : "มวลกล้ามเนื้อ"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[3].resource.valueQuantity.value +
                                " " +
                                rows[3].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Total body fat"
                                : "มวลไขมัน"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[4].resource.valueQuantity.value +
                                " " +
                                rows[4].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Fasting Blood Sugar"
                                : "ค่าน้ำตาลในเลือดหลังอดอาหาร"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[5].resource.valueQuantity.value +
                                " " +
                                rows[5].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Oral gluclose tolerance"
                                : "ค่าความทนต่อน้ำตาล"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[6].resource.valueQuantity.value +
                                " " +
                                rows[6].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "C-peptide"
                                : "C-peptide"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[7].resource.valueQuantity.value +
                                " " +
                                rows[7].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          {/* <div className={classes.list_data}>
                          <Typography
                            variant="h6"
                            className={classes.screening_data}
                          >
                            {props.language === "en" ? "HOMA-IR" : "HOMA-IR"}
                          </Typography>
                          <Typography variant="h6">
                            {rows[8].resource.valueQuantity.value +
                              " " +
                              rows[8].resource.valueQuantity.unit}
                          </Typography>
                        </div>
                        <div className={classes.list_data}>
                          <Typography
                            variant="h6"
                            className={classes.screening_data}
                          >
                            {props.language === "en" ? "IL-10" : "IL-10"}
                          </Typography>
                          <Typography variant="h6">
                            {rows[9].resource.valueQuantity.value +
                              " " +
                              rows[9].resource.valueQuantity.unit}
                          </Typography>
                        </div> */}
                        </div>
                      )}
                  </TabPanel>
                  <TabPanel value={tab} index={2}>
                    {!props.patient.wristBandId ? (
                      <Typography variant="h6">
                        {props.language === "en"
                          ? "Lorem Ipsum"
                          : "ไม่มีข้อมูลการเข้าอบรมการใช้อุปกรณ์"}
                      </Typography>
                    ) : (
                        <Typography variant="h6">
                          {" "}
                          {props.language === "en"
                            ? "Lorem Ipsum"
                            : "หมายเลขริสแบรนด์คือ"}{" "}
                          {props.patient.wristBandId}
                        </Typography>
                      )}
                  </TabPanel>
                  <TabPanel value={tab} index={3}>
                    {!rows[10] ? (
                      <Typography variant="h6">
                        {props.language === "en"
                          ? "Lorem Ipsum"
                          : "ไม่มีข้อมูลการคัดกรอง"}
                      </Typography>
                    ) : (
                        <div>
                          <Typography variant="h6">
                            {props.language === "en"
                              ? "Lorem Ipsum"
                              : "มีข้อมูลการคัดกรอง"}
                          </Typography>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "waist" : "รอบเอว"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[10].resource.valueQuantity.value +
                                " " +
                                rows[10].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "weight" : "น้ำหนัก"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[11].resource.valueQuantity.value +
                                " " +
                                rows[11].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "BMI" : "BMI"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[12].resource.valueQuantity.value +
                                " " +
                                rows[12].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Muscle Mass"
                                : "มวลกล้ามเนื้อ"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[13].resource.valueQuantity.value +
                                " " +
                                rows[13].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Total body fat"
                                : "มวลไขมัน"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[14].resource.valueQuantity.value +
                                " " +
                                rows[14].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Fasting Blood Sugar"
                                : "ค่าน้ำตาลในเลือดหลังอดอาหาร"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[15].resource.valueQuantity.value +
                                " " +
                                rows[15].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "C-peptide"
                                : "C-peptide"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[17].resource.valueQuantity.value +
                                " " +
                                rows[17].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          {/* <div className={classes.list_data}>
                          <Typography
                            variant="h6"
                            className={classes.screening_data}
                          >
                            {props.language === "en" ? "HOMA-IR" : "HOMA-IR"}
                          </Typography>
                          <Typography variant="h6">
                            {rows[18].resource.valueQuantity.value +
                              " " +
                              rows[18].resource.valueQuantity.unit}
                          </Typography>
                        </div>
                        <div className={classes.list_data}>
                          <Typography
                            variant="h6"
                            className={classes.screening_data}
                          >
                            {props.language === "en" ? "IL-10" : "IL-10"}
                          </Typography>
                          <Typography variant="h6">
                            {rows[19].resource.valueQuantity.value +
                              " " +
                              rows[19].resource.valueQuantity.unit}
                          </Typography>
                        </div> */}
                        </div>
                      )}
                  </TabPanel>
                  <TabPanel value={tab} index={4}>
                    {!rows[20] ? (
                      <Typography variant="h6">
                        {props.language === "en"
                          ? "Lorem Ipsum"
                          : "ไม่มีข้อมูลการคัดกรอง"}
                      </Typography>
                    ) : (
                        <div>
                          <Typography variant="h6">
                            {props.language === "en"
                              ? "Lorem Ipsum"
                              : "มีข้อมูลการคัดกรอง"}
                          </Typography>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "waist" : "รอบเอว"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[20].resource.valueQuantity.value +
                                " " +
                                rows[20].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "weight" : "น้ำหนัก"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[21].resource.valueQuantity.value +
                                " " +
                                rows[21].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en" ? "BMI" : "BMI"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[22].resource.valueQuantity.value +
                                " " +
                                rows[22].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Muscle Mass"
                                : "มวลกล้ามเนื้อ"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[23].resource.valueQuantity.value +
                                " " +
                                rows[23].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Total body fat"
                                : "มวลไขมัน"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[24].resource.valueQuantity.value +
                                " " +
                                rows[24].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "Fasting Blood Sugar"
                                : "ค่าน้ำตาลในเลือดหลังอดอาหาร"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[25].resource.valueQuantity.value +
                                " " +
                                rows[25].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          <div className={classes.list_data}>
                            <Typography
                              variant="h6"
                              className={classes.screening_data}
                            >
                              {props.language === "en"
                                ? "C-peptide"
                                : "C-peptide"}
                            </Typography>
                            <Typography variant="h6">
                              {rows[27].resource.valueQuantity.value +
                                " " +
                                rows[27].resource.valueQuantity.unit}
                            </Typography>
                          </div>
                          {/* <div className={classes.list_data}>
                          <Typography
                            variant="h6"
                            className={classes.screening_data}
                          >
                            {props.language === "en" ? "HOMA-IR" : "HOMA-IR"}
                          </Typography>
                          <Typography variant="h6">
                            {rows[28].resource.valueQuantity.value +
                              " " +
                              rows[28].resource.valueQuantity.unit}
                          </Typography>
                        </div>
                        <div className={classes.list_data}>
                          <Typography
                            variant="h6"
                            className={classes.screening_data}
                          >
                            {props.language === "en" ? "IL-10" : "IL-10"}
                          </Typography>
                          <Typography variant="h6">
                            {rows[29].resource.valueQuantity.value +
                              " " +
                              rows[29].resource.valueQuantity.unit}
                          </Typography>
                        </div> */}
                        </div>
                      )}
                  </TabPanel>
                </Paper>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return <div>{record_patient}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
  patient: state.patient,
});

const mapDispatchToProps = (dispatch) => ({
  setPatient: (data) => dispatch(setPatient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordPatient);
