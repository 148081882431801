const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  search_button: {
    width: 200,
    borderRadius: 16,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  patient_status_button: {},
  table_cell: {
    padding: theme.spacing(0),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
  sex: {
    width: 30,
    height: 30,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    padding: 1,
  },
  logo: {
    width: 64,
    height: 64,
    marginRight: 24,
  },
});

export default styles;
