import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  // Checkbox,
  InputBase,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  Button,
  FormControl,
  Typography,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import male from "../../../assets/images/pic-icon-male.png";
import female from "../../../assets/images/pic-icon-female.png";
import style from "./style";
import MainMenu from "../main_menu";
import { ConvertToModel } from "../../../utility/patient";
import {
  PatientWaitForInvitation,
  PatientGetPatientsScreening,
  GetHistorytaking,
  GetPrescreentaking,
} from "../../../dataservice/patient/patient";
import { SendVolunteerInvitation } from "../../../dataservice/email/email";
import * as XLSX from "xlsx";

let SendInvitation = (props) => {
  const columns = [
    { id: "firstName", label: "ชื่อ", minWidth: 50 },
    { id: "lastName", label: "นามสกุล", minWidth: 50 },
    { id: "gender", label: "เพศ", minWidth: 50 },
    { id: "phone", label: "ติดต่อ", minWidth: 50 },
    { id: "group", label: "กลุ่ม", minWidth: 50 },
    { id: "homair", label: "homair", minWidth: 50 },
    { id: "cpeptide", label: "cpeptide", minWidth: 50 },
  ];

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              color="primary"
            />
          </TableCell> */}
          {columns.map((column) => (
            <TableCell
              key={column.id}
              style={{ minWidth: column.minWeight }}
              className={classes.table_head_cell}
              align="center"
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const classes = makeStyles(style)();
  const [patients, setPatients] = useState([]);
  const [showPatients, setShowPatients] = useState(patients);
  const [selected, setSelected] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [tableRowPerPage, setTableRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const handleOnPrescreeningExport = async () => {
    const temp = [...patients];

    const historytaking = await GetPrescreentaking();

    const historytakingData = historytaking.data.data;

    for (let i = 0; i < temp.length; i++) {
      for (let j = 0; j < Object.keys(historytakingData).length; j++) {
        if (temp[i].nationalID == historytakingData[j].nationalID) {
          temp[i].historytakingData = historytakingData[j];
        }
      }
    }

    let dataExport = [];

    for (let i = 0; i < temp.length; i++) {
      let subStatusString = `${temp[i].subStatus}`;

      if (temp[i].subStatus === 0) {
        subStatusString =
          "อาสาสมัครจะสามารถสร้างการนัดหมายได้หลังจากที่ได้รับ Email จากทางสภากาชาด";
      }
      if (temp[i].subStatus === 1) {
        subStatusString =
          "อาสาสมัครสามารถทำการนัดหมายได้โดยการเลือกที่เมนู ทำการนัดหมาย";
      }
      if (temp[i].subStatus === 2) {
        subStatusString =
          "อาสาสมัครได้ทำการนัดหมายเรียบร้อย โปรดติดต่อที่สถานีกาชาดที่นัดหมายไว้ตามวันที่ได้ทำนัดหมาย";
      }
      if (temp[i].subStatus === 9) {
        subStatusString =
          "อาสาสมัครได้รับการวินิจฉัยจากผลการตรวจเลือด ว่าภาวะร่างกายของท่าน ไม่ตรงกับเกณฑ์การคัดกรอง ทำให้ไม่อยู่ในเกณฑ์สำหรับขั้นตอนการต่อไปของการวิจัย";
      }

      const p = {
        name: temp[i].firstName,
        lastName: temp[i].lastName,
        gender: temp[i].gender,
        phone: temp[i].phone,
        group: temp[i].group,
        homair:
          temp[i].screening.length === 0 ? null : temp[i].screening[0].homair,
        cpeptide:
          temp[i].screening.length === 0 ? null : temp[i].screening[0].cpeptide,
        subStatus: subStatusString,
        systolicPressure:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.systolicPressure,
        diastolicPressure:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.diastolicPressure,
        restingHeartRate:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.restingHeartRate,
        weight:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.weight,
        height:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.height,
        bmi:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.bmi,
        isDiabetes:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isDiabetes,
        isDiabetesInFamily:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isDiabetesInFamily,
        isHypertension:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isHypertension,
        isCardiovascular:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isCardiovascular,
        isPulmonary:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isPulmonary,
        isCerebrovascular:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isCerebrovascular,
        isCancer:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isCancer,
        isKidneyFailure:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isKidneyFailure,
        isDepress:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isDepress,
        isOnPsychiatryDrug:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isOnPsychiatryDrug,
        isEffectOnIsulin:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isEffectOnIsulin,
        isAbnormalMuscleTone:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isAbnormalMuscleTone,
      };
      dataExport.push(p);
    }

    let wb = XLSX.utils.book_new();

    let ws = XLSX.utils.json_to_sheet(dataExport);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "patients.xlsx");
  };

  const handleOnExportPrescreen = async () => {
    let checkAllLocation = new URL(window.location.href).searchParams.get('all') != null?true:false;
    let locationId = props.user.location.id;
    const prescreeningtaking = await GetPrescreentaking(checkAllLocation ? "" : locationId);
    const prescreeningtakingData = prescreeningtaking.data.data;

    const keys = Object.keys(prescreeningtakingData);

    let dataExport = [];
    let passPrescreen = true;

    let h = [ ["รายงานสรุปรายชื่อผู้ทำแบบคัดกรอง Prescreening"],
              [],
              ["รหัสสถานี","ชื่อ","นามสกุล","เลขบัตรประชาชน","เบอร์โทร","อีเมล์","BMI","อายุ","เพศ","สถานะข้อมูลซ้ำซ้อน","สถานะ 8 ข้อคำถาม","มีโรคเบาหวาน","มีสมาชิกเป็นเบาหวาน","ทำกิจกรรมในกิจวัตรประจำวันได้ปกติ","มี Smartphone","สามารถใช้อุปกรณ์ตรวจจับชีพจรชนิดสวมข้อมือได้","มีการออกกำลังกายความหนักตั้งแต่ระดับ Moderate Intensity ขึ้นไป มากกว่า 30 นาที/ครั้ง และเกิน 3 ครั้ง/สัปดาห์ หรือระยะเวลารวมกันเกิน 90 นาที/สัปดาห์"
            ]];

    let nationalID = null;
    let nationalIDList = [];
    let checkDuplicate = false;
    for (let i = 0; i < keys.length; i++) {
      passPrescreen = true;
      
      nationalID = prescreeningtakingData[keys[i]].nationalID;
      checkDuplicate = false;
      if(nationalIDList.indexOf(nationalID) != -1)
        checkDuplicate = true;
      else
        nationalIDList.push(nationalID);

      if(prescreeningtakingData[keys[i]].isOnSmartPhone == false ||
        prescreeningtakingData[keys[i]].isWearableWrist == false ||
        prescreeningtakingData[keys[i]].isRiskForDiabetesDirectFamily == false ||
        prescreeningtakingData[keys[i]].isExercise == true ||
        prescreeningtakingData[keys[i]].isOkayForDailyActivities == false ||
        prescreeningtakingData[keys[i]].isOnSmartPhone == false ||
        prescreeningtakingData[keys[i]].sections[0].isDiabetes == true ||
        prescreeningtakingData[keys[i]].age < 35 || prescreeningtakingData[keys[i]].age > 65 ||
        prescreeningtakingData[keys[i]].bmi < 25 || prescreeningtakingData[keys[i]].bmi > 40
        ) 
        passPrescreen = false;

      const p = {
        locationId: prescreeningtakingData[keys[i]].location,
        name: 
          prescreeningtakingData[keys[i]].sections == null || prescreeningtakingData[keys[i]].sections.length < 2?
            "": prescreeningtakingData[keys[i]].sections[1].firstName,
        lastName: 
          prescreeningtakingData[keys[i]].sections == null || prescreeningtakingData[keys[i]].sections.length < 2?
            "": prescreeningtakingData[keys[i]].sections[1].lastName,
        nationalID: prescreeningtakingData[keys[i]].nationalID,
        phone: prescreeningtakingData[keys[i]].phone,
        email: prescreeningtakingData[keys[i]].email,
        bmi: prescreeningtakingData[keys[i]].bmi,
        age: prescreeningtakingData[keys[i]].age,
        gender: prescreeningtakingData[keys[i]].gender == 0?"หญิง":"ชาย",
        isDuplicate: checkDuplicate?"มีข้อมูลก่อนหน้าแล้ว":"ไม่ซ้ำ",
        isPass: passPrescreen?"ผ่าน":"ไม่ผ่าน",
        isDiabetes: prescreeningtakingData[keys[i]].sections[0].isDiabetes?"มี":"ไม่มี",
        isRiskForDiabetesDirectFamily: prescreeningtakingData[keys[i]].isRiskForDiabetesDirectFamily?"มี":"ไม่มี",
        isOkayForDailyActivities: prescreeningtakingData[keys[i]].isOkayForDailyActivities?"ได้":"ไม่ได้",
        isOnSmartPhone: prescreeningtakingData[keys[i]].isOnSmartPhone?"มี":"ไม่มี",
        isWearableWrist: prescreeningtakingData[keys[i]].isWearableWrist?"ได้":"ไม่ได้",
        isExercise: prescreeningtakingData[keys[i]].isExercise?"มี":"ไม่มี",
      };
      dataExport.push(p);
    }

    console.log(dataExport);

    //let wb = XLSX.utils.book_new();
    //let ws = XLSX.utils.json_to_sheet(dataExport);
    //XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(h);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.utils.sheet_add_json(ws, dataExport, { origin: 'A4'});

    XLSX.writeFile(wb, "prescreens.xlsx");
  };

  const handleOnExport = async () => {
    const temp = [...patients];
    let locationId = props.user.location.id;

    const historytaking = await GetHistorytaking();
    const historytakingData = historytaking.data.data;

    for (let i = 0; i < temp.length; i++) {
      for (let j = 0; j < Object.keys(historytakingData).length; j++) {
        if (temp[i].nationalID == historytakingData[j].nationalID) {
          temp[i].historytakingData = historytakingData[j];
        }
      }
    }

    let dataExport = [];

    let h = [ ["รายงานสรุปรายชื่ออาสาสมัครที่ผ่านการคัดกรอง Pre-screening"],
              [],
              ["รหัสสถานี","ชื่อ","นามสกุล","เลขบัตรประชาชน","เพศ","เบอร์โทร","ตอบรับยืนยันสมัครใจเป็นอาสา","ผลตรวจเลือด","HOMA-IR","C-Peptide","ความดันเลือด(บน)","ความดันเลือด(ล่าง)","อัตราการเต้นของหัวใจ",
               "น้ำหนัก","ส่วนสูง","BMI","เป็นเบาหวาน","มีสมาชิกเป็นเบาหวาน","มีความดันโลหิตสูง","มีโรคหัวใจ","มีภาวะความดันหลอดเลือดปอดสูง","มีความเสี่ยงเนื่องจากโรคหลอดเลือดสมอง",
               "เป็นมะเร็ง","มีภาวะไตเสื่อม","มีภาวะซึมเศร้า","มีความเสี่ยงเนื่องจากมีการใช้ยาทางจิตเวช","มีภาวะดื้ออินซูลิน","มีความผิดปกติของกล้ามเนื้อ กระดูก เอ็นและข้อต่อ"
            ]];

    for (let i = 0; i < temp.length; i++) {
      let subStatusString = `${temp[i].subStatus}`;
      let bloodTest = 'ผ่าน';

      if (temp[i].subStatus === 0) {
        subStatusString =
          "อาสาสมัครจะสามารถสร้างการนัดหมายได้หลังจากที่ได้รับ Email จากทางสภากาชาด";
      }
      if (temp[i].subStatus === 1) {
        subStatusString =
          "อาสาสมัครสามารถทำการนัดหมายได้โดยการเลือกที่เมนู ทำการนัดหมาย";
      }
      if (temp[i].subStatus === 2) {
        subStatusString =
          "อาสาสมัครได้ทำการนัดหมายเรียบร้อย โปรดติดต่อที่สถานีกาชาดที่นัดหมายไว้ตามวันที่ได้ทำนัดหมาย";
      }
      if (temp[i].subStatus === 9) {
        subStatusString =
          "อาสาสมัครได้รับการวินิจฉัยจากผลการตรวจเลือด ว่าภาวะร่างกายของท่าน ไม่ตรงกับเกณฑ์การคัดกรอง ทำให้ไม่อยู่ในเกณฑ์สำหรับขั้นตอนการต่อไปของการวิจัย";
          bloodTest = 'ไม่ผ่าน';
      }
      
      if(temp[i].screening.length === 0 || temp[i].screening[0].homair == null ||  temp[i].screening[0].homair  == '')
        bloodTest = 'รอผลเลือด';

      let volunteerString = 'รอยืนยันเข้าร่วม';
      if(temp[i].isVolunteer == 1)
        volunteerString = 'ยืนยันแล้ว';
      

      const p = {
        locationId: temp[i].locationId,
        name: temp[i].firstName,
        lastName: temp[i].lastName,
        nationalID: temp[i].nationalID,
        gender: temp[i].gender,
        phone: temp[i].phone,
        isVolunteer: volunteerString,
        bloodTest: bloodTest,
        homair:
          temp[i].screening.length === 0 ? null : temp[i].screening[0].homair,
        cpeptide:
          temp[i].screening.length === 0 ? null : temp[i].screening[0].cpeptide,
        //subStatus: subStatusString,
        systolicPressure:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.systolicPressure,
        diastolicPressure:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.diastolicPressure,
        restingHeartRate:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.restingHeartRate,
        weight:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.weight,
        height:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.height,
        bmi:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.bmi,
        isDiabetes:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isDiabetes,
        isDiabetesInFamily:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isDiabetesInFamily,
        isHypertension:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isHypertension,
        isCardiovascular:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isCardiovascular,
        isPulmonary:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isPulmonary,
        isCerebrovascular:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isCerebrovascular,
        isCancer:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isCancer,
        isKidneyFailure:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isKidneyFailure,
        isDepress:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isDepress,
        isOnPsychiatryDrug:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isOnPsychiatryDrug,
        isEffectOnIsulin:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isEffectOnIsulin,
        isAbnormalMuscleTone:
          temp[i].historytakingData == null
            ? ""
            : temp[i].historytakingData.answer.isAbnormalMuscleTone,
      };
      dataExport.push(p);
    }
    
    console.log(dataExport);

    let wb = XLSX.utils.book_new();
    //let ws = XLSX.utils.json_to_sheet(dataExport);
    //XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    //const ws = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(h);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.utils.sheet_add_json(ws, dataExport, { origin: 'A4'});

    XLSX.writeFile(wb, "patients.xlsx");
  };

  useEffect(() => {
    async function fetchData() {
      let checkAllLocation = new URL(window.location.href).searchParams.get('all') != null?true:false;
      let locationId = props.user.location.id;

      //await PatientWaitForInvitation(props.user.location.id).then((res) => {
      await PatientGetPatientsScreening(checkAllLocation ? "" : locationId).then((res) => {
        let patientObjects = res.data.entry.map((entry) =>
          ConvertToModel(entry.resource)
        );

        setPatients(patientObjects);
        setShowPatients(patientObjects);
      });
    }
    fetchData();
  }, [props.user.location.id]);

  const searchPatient = () => {
    let temp_data = [...patients];
    if (searchString !== "") {
      temp_data = temp_data.filter(
        (patient) =>
          patient.firstName.includes(searchString.trim()) ||
          patient.lastName.includes(searchString.trim())
      );
    }
    setShowPatients(temp_data);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = showPatients.map((patient) => patient.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function onChangePage(e, newPage) {
    setTablePage(newPage);
  }
  function onChangeRowsPerPage(e) {
    setTableRowsPerPage(+e.target.value);
    setTablePage(0);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  async function sendInvitation() {
    const users = selected.map((user) => parseInt(user));
    const payload = {
      users: users,
    };
    const config = {
      headers: { Authorization: `Bearer ${props.user.access_token}` },
    };
    await SendVolunteerInvitation(payload, config).then((res) => {
      setBackdrop(true);
      if (res.data.status === "success") {
        setOpen(true);
        setBackdrop(false);
        window.location.reload(false);
      } else {
        throw new Error("Something error");
      }
    });
  }

  const table = (
    <React.Fragment>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={showPatients.length}
          />
          <TableBody>
            {showPatients
              .slice(
                tablePage * tableRowPerPage,
                tablePage * tableRowPerPage + tableRowPerPage
              )
              .map((patient, index) => {
                const isItemSelected = isSelected(patient.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    onClick={(event) => handleClick(event, patient.id)}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    className={classes.table_row}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        color="primary"
                      />
                    </TableCell> */}
                    <TableCell
                      className={classes.table_cell}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {patient.firstName}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.lastName}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.gender === "male" ? (
                        <img src={male} alt="sex" className={classes.sex} />
                      ) : (
                        <img src={female} alt="sex" className={classes.sex} />
                      )}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.phone}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.screening.length === 0 ? null : (
                        <span> {patient.screening[0].homair}</span>
                      )}
                    </TableCell>
                    <TableCell className={classes.table_cell}>
                      {patient.screening.length === 0 ? null : (
                        <span> {patient.screening[0].cpeptide}</span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );

  let send_invitation = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} style={{ padding: 16 }}>
          <Grid item xs={12} align="left">
            <Typography variant="h4">
              Download รายชื่ออาสาสมัคร
              {/* ส่ง Email สำหรับเชิญเป็นอาสาสมัคร */}
            </Typography>
          </Grid>
          <Grid item xs={12} align="left">
            <Paper style={{ borderRadius: 24 }}>
              <Grid item xs={12} style={{ display: "flex", padding: 24 }}>
                <FormControl
                  style={{
                    flexGrow: 1,
                    marginRight: 40,
                    backgroundColor: "#eeeeee",
                    padding: 8,
                    borderRadius: 16,
                  }}
                >
                  <InputBase
                    placeholder="ค้นหารายชื่อ"
                    style={{ marginLeft: 16 }}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </FormControl>
                <Button
                  className={classes.search_button}
                  onClick={searchPatient}
                >
                  <Typography>ค้นหา</Typography>
                </Button>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={showPatients.length}
                  rowsPerPage={tableRowPerPage}
                  page={tablePage}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 24 }}>
                {table}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ padding: 24 }}>
            {/* <Button
              disabled={selected.length === 0 ? true : false}
              onClick={() => sendInvitation()}
              className={classes.enter_button}
            >
              ส่งอีเมล
            </Button> */}

            <Button
              onClick={() => handleOnExportPrescreen()}
              className={classes.dialog_button_cancel}
              >
              ดาวน์โหลดรายชื่อคัดกรองทั้งหมด
            </Button>
          </Grid>
          <Grid item xs={12} style={{ padding: 24 }}>
            <Button
              onClick={() => handleOnExport()}
              className={classes.enter_button}
            >
              ดาวน์โหลดรายชื่อผู้ผ่าน Prescreening
            </Button>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            ส่งอีเมลสำเร็จ
          </Alert>
        </Snackbar>
      </div>
    </div>
  );

  return <div>{send_invitation}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
});

export default connect(mapStateToProps, undefined)(SendInvitation);
