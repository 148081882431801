import { createStore } from 'redux'
import reducer from '../reducers'
import { loadState, saveState } from './localStorage'

const persistStore = loadState()
const store = createStore(reducer, persistStore)

store.subscribe(() => {
	saveState(store.getState())
})

export default store