const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0),
  },
  profile_header: {
    padding: theme.spacing(4),
    borderRadius: 24,
  },
  profile_subheader: {
    display: "flex",
  },
  avatar: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(2),
  },
  sex: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
  },
  text_left: {
    textAlign: "left",
  },
  body: {
    width: "100%",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  enter_button: {
    width: "100%",
    minHeight: 56,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  enter_temporary_button: {
    width: "100%",
    minHeight: 56,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  dialog_button_send: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialog_button_cancel: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  radioSpace: {
    width: 400,
    minWidth: 100,
  },
  action_button: {
    textTransform: "none",
    width: "90%",
    maxWidth: 600,
    minWidth: 300,
    minHeight: 56,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    marginBottom: theme.spacing(2),
  },
});

export default styles;
