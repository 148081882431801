const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    textAlign: "center",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0),
  },
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minWidth: 240,
    minHeight: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.background.default,
  },
  body_paper: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.default,
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: 160,
    height: 160,
    marginBottom: theme.spacing(8),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  action_button: {
    textTransform: "none",
    width: "90%",
    maxWidth: 600,
    minWidth: 300,
    minHeight: 56,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    marginBottom: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
});

export default styles;
