import { Appointment, Location } from "../route";
import axios from "axios";

export const AppointmentGetBookedByDate = (locationId, date) => {
  const url =
    Location() + "/" + locationId + "/appointment?status=booked&date=" + date;
  return axios.get(url);
};

export const AppointmentGetByDate = (locationId, date) => {
  const url = Location() + "/" + locationId + "/appointment?date=" + date;
  return axios.get(url);
};

export const AppointmentCreateFirstVisit = (payload) => {
  const url = Appointment() + "/createFirstVisit";
  return axios.post(url, payload);
};

export const AppointmentCancelFirstVisit = (payload) => {
  const url = Appointment() + "/cancelFirstVisit";
  return axios.post(url, payload);
};

export const GetAppointmentObservation = (appointmentId) => {
  const url = Appointment() + "/" + appointmentId + "/observation";
  return axios.get(url);
};

export const GetAvailableDates = (config, locationId) => {
  const url = Appointment() + "/availabledates?locationId=" + locationId;
  return axios.get(url, config);
};

export const CreateAvailableDates = (payload, config) => {
  const url = Appointment() + "/availabledates";
  return axios.post(url, payload, config);
};
