export const PATIENTSTATUS = {
  HIDDENSTATUS: 1,
  SCREENING: 2,
  FIRSTVISIT: 3,
  FOLLOWUP1: 4,
  FOLLOWUP2: 5,
};

export const APPOINTMENTTYPE = {
  SCREENING: "Screening",
  FIRSTVISIT: "First Visit",
  FOLLOWUP1: "Follow Up #1",
  FOLLOWUP2: "Follow Up #2",
};

export const APPOINTMENTSTATUS = {
  proposed: "proposed",
  pending: "pending",
  booked: "booked",
  arrived: "arrived",
  fulfilled: "fulfilled",
  cancelled: "cancelled",
  noshow: "noshow",
  enteredInError: "entered-in-error",
  checkedIn: "checked-in",
  waitlist: "waitList",
};
