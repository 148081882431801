import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import ClearIcon from "@material-ui/icons/Clear";
import { BACKENDURL } from "../../../../utility/config";
import style from "./style";
import maleAvatar from "../../../../assets/images/pic-man-profile.png";
import femaleAvatar from "../../../../assets/images/pic-woman-profile.png";
import MainMenu from "../../main_menu";
import { GetAppointmentObservation } from "../../../../dataservice/appointment/appointment";
import { ObservationPostScreening } from "../../../../dataservice/observation/observation";
import { UpdatePatientStatusAndAppointmentStatus } from "../../../../dataservice/patient/patient";
import {
  MPGroupGetLabId,
  MPGroupCheckSum,
  MPGroupAddDonor,
} from "../../../../dataservice/mpgroup/mpgroup";
import { convertPatientStatusForm } from "../../../../utility/appointment";
import { convertObservationToModel } from "../../../../utility/observation";
import { APPOINTMENTSTATUS } from "../../../../utility/const";

let EnterFollowup = (props) => {
  const classes = makeStyles(style)();
  const [weight, setWeight] = useState(-1);
  const [height, setHeight] = useState(-1);
  const [waist, setWaist] = useState(-1);
  const [fatmass, setFatmass] = useState(-1);
  const [musclemass, setMusclemass] = useState(-1);
  const [fbs, setFbs] = useState(-1);
  const [ogtt, setOgtt] = useState(-1);
  const [ogttType, setOgttType] = useState(1);
  const [fbsType, setFbsType] = useState(1);
  const [hba1c, setHba1c] = useState(-1);
  const [systolic, setSystolic] = useState(-1);
  const [diastolic, setDiastolic] = useState(-1);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  let history = useHistory();

  useEffect(() => {
    async function fetchData() {
      if (
        props.patient.appointmentStatus === APPOINTMENTSTATUS.arrived ||
        props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled
      ) {
        await GetAppointmentObservation(props.patient.appointmentId).then(
          (res) => {
            const observationObject = convertObservationToModel(res.data.entry);
            setWeight(observationObject.weight);
            setWaist(observationObject.waist);
            setFatmass(observationObject.fatmass);
            setMusclemass(observationObject.musclemass);
            setFbs(observationObject.fbs);
            setOgtt(observationObject.ogtt);
            setHeight(observationObject.height);
            setHba1c(observationObject.hba1c);
            setSystolic(observationObject.systolic);
            setDiastolic(observationObject.diastolic);
          }
        );
      }
    }
    fetchData();
  }, [props.patient.appointmentStatus, props.patient.appointmentId]);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  function generateFbsType() {
    let fbsOption = [
      <option value={1} key={"mg/dL"}>
        {"mg/dL"}
      </option>,
      <option value={2} key={"mmol/L"}>
        {"mmol/L"}
      </option>,
    ];

    return fbsOption;
  }

  function generateOgttType() {
    let fbsOption = [
      <option value={1} key={"mg/dL"}>
        {"mg/dL"}
      </option>,
      <option value={2} key={"mmol/L"}>
        {"mmol/L"}
      </option>,
    ];

    return fbsOption;
  }
  function generateWeight() {
    let weightOption = [];
    weightOption.push(
      <option value={-1} disabled key="weightOption">
        {props.language === "en" ? "Weight" : "น้ำหนัก"}
      </option>
    );
    for (var i = 40; i < 200; i++) {
      weightOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return weightOption;
  }
  function generateHeight() {
    let heightOption = [];
    heightOption.push(
      <option value={-1} disabled key="heightOption">
        {props.language === "en" ? "Height" : "ส่วนสูง"}
      </option>
    );
    for (var i = 100; i < 200; i++) {
      heightOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return heightOption;
  }
  function generateWaist() {
    let waistOption = [];
    waistOption.push(
      <option value={-1} disabled key="waistOption">
        {props.language === "en" ? "Waist" : "เส้นรอบเอว"}
      </option>
    );
    for (var i = 40; i < 150; i++) {
      waistOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return waistOption;
  }
  function generateFatmass() {
    let fatmassOption = [];
    fatmassOption.push(
      <option value={-1} disabled key="fatmassOption">
        {props.language === "en" ? "Fatmass" : "มวลไขมัน"}
      </option>
    );
    for (var i = 10; i < 45; i++) {
      fatmassOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return fatmassOption;
  }
  function generateMusclemass() {
    let musclemassOption = [];
    musclemassOption.push(
      <option value={-1} disabled key="musclemassOption">
        {props.language === "en" ? "Musclemass" : "มวลกล้ามเนื้อ"}
      </option>
    );
    for (var i = 10; i < 45; i++) {
      musclemassOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return musclemassOption;
  }
  function generateFbs() {
    let fbsOption = [];
    fbsOption.push(
      <option value={-1} disabled key="fbsOption">
        {props.language === "en" ? "Fbs" : "ค่าน้ำตาลหลังอดอาหาร"}
      </option>
    );
    for (var i = 30; i < 400; i++) {
      fbsOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return fbsOption;
  }
  function generateOgtt() {
    let ogttOption = [];
    ogttOption.push(
      <option value={-1} disabled key="ogttOption">
        {props.language === "en" ? "Ogtt" : "ค่าความทนต่อน้ำตาล"}
      </option>
    );
    for (var i = 30; i < 500; i++) {
      ogttOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return ogttOption;
  }
  function generateHba1c() {
    let hba1cOption = [];
    hba1cOption.push(
      <option value={-1} disabled key="Hba1cOption">
        {props.language === "en" ? "hba1c" : "ค่า HbA1c"}
      </option>
    );
    for (var i = 20; i < 80; i++) {
      hba1cOption.push(
        <option value={i / 10} key={i / 10}>
          {i / 10}
        </option>
      );
    }
    return hba1cOption;
  }
  function generateSystolic() {
    let systolicOption = [];
    systolicOption.push(
      <option value={-1} disabled key="systolicOption">
        {props.language === "en"
          ? "Systolic blood pressure"
          : "ความดันโลหิตค่าบน"}
      </option>
    );
    for (var i = 40; i < 250; i++) {
      systolicOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return systolicOption;
  }
  function generateDiastolic() {
    let diastolicOption = [];
    diastolicOption.push(
      <option value={-1} disabled key="diastolicOption">
        {props.language === "en"
          ? "Diastolic blood pressure"
          : "ความดันโลหิตค่าล่าง"}
      </option>
    );
    for (var i = 40; i < 200; i++) {
      diastolicOption.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return diastolicOption;
  }

  function validateFollowup() {
    const validate =
      waist !== -1 &&
      weight !== -1 &&
      height !== -1 &&
      fatmass !== -1 &&
      musclemass !== -1 &&
      fbs !== -1 &&
      ogtt !== -1 &&
      systolic !== -1 &&
      diastolic !== -1;
    return validate;
  }
  function onOpenSubmitDialog() {
    setSubmitDialog(true);
  }
  function onCloseSubmitDialog() {
    setSubmitDialog(false);
  }
  function onOpenErrorDialog() {
    setErrorDialog(true);
  }
  function onCloseErrorDialog() {
    setErrorDialog(false);
  }

  async function onSubmitFollowup() {
    onCloseSubmitDialog();
    const labnumber = await MPGroupGetLabId({
      sitecode: props.user.location.siteCode,
      checksum: props.user.location.checkSum,
    }).then((res) => {
      if (res.data.status === "success") {
        return res.data.data.labnumber;
      } else {
        throw new Error("Cannot get labnumber");
      }
    });
    const checksum = await MPGroupCheckSum({
      labnumber: labnumber,
    }).then((res) => {
      if (res.data.status === "success") {
        return res.data.data.checksum;
      } else {
        throw new Error("Cannot get checksum");
      }
    });
    const encouter = {
      reference: BACKENDURL + "/encounter/" + props.patient.appointmentId,
      type: "Encounter",
    };
    const subject = {
      reference: BACKENDURL + "/patient/" + props.patient.id,
      type: "Patient",
    };
    const extension = [
      {
        url: BACKENDURL + "labNumber",
        value: labnumber,
      },
    ];
    const bmi = parseFloat(weight) / (parseFloat(height) / 100) ** 2;
    let birthDate = new Date(props.patient.birthDate);
    let payload = {
      sitecode: props.user.location.siteCode,
      donorid:
        props.patient.id +
        "_" +
        props.patient.nationalID +
        "_" +
        props.patient.status,
      labnumber: labnumber,
      patient: {
        nationalID: props.patient.nationalID,
        sex: props.patient.sex,
        firstName: props.patient.firstName,
        lastName: props.patient.lastName,
        birthdate:
          String(birthDate.getDate()).padStart(2, "0") +
          "/" +
          String(birthDate.getMonth() + 1).padStart(2, "0") +
          "/" +
          birthDate.getFullYear(),
        weight: weight.toString(),
        height: height.toString(),
        bmi: bmi.toFixed(1).toString(),
      },
      printer_name: "Printer1",
      checksum: checksum,
    };
    MPGroupAddDonor(payload)
      .then((res) => {
        if (res.data.status === "success") {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://loinc.org",
                  code: "29463-7",
                },
              ],
            },
            valueQuantity: {
              value: weight,
              unit: "kg",
              system: "http://unitsofmeasure.org",
              code: "kg",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://loinc.org",
                  code: "8302-2",
                },
              ],
            },
            valueQuantity: {
              value: height,
              unit: "cm",
              system: "http://unitsofmeasure.org",
              code: "cm",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "276361009",
                },
              ],
            },
            valueQuantity: {
              value: waist,
              unit: "cm",
              system: "http://unitsofmeasure.org",
              code: "cm",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "248361005",
                },
              ],
            },
            valueQuantity: {
              value: fatmass,
              unit: "percent",
              system: "http://unitsofmeasure.org",
              code: "percent",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "163636005",
                },
              ],
            },
            valueQuantity: {
              value: musclemass,
              unit: "percent",
              system: "http://unitsofmeasure.org",
              code: "percent",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "271062006",
                },
              ],
            },
            valueQuantity: {
              value: fbsType == 2 ? fbs * 18.0182 : fbs,
              unit: "mg/dl",
              system: "http://unitsofmeasure.org",
              code: "mg/dl",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "113076002",
                },
              ],
            },
            valueQuantity: {
              value: ogttType == 2 ? ogtt * 18.0182 : ogtt,
              unit: "mg/dl",
              system: "http://unitsofmeasure.org",
              code: "mg/dl",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "43396009",
                },
              ],
            },
            valueQuantity: {
              value: hba1c,
              unit: "percent",
              system: "http://unitsofmeasure.org",
              code: "percent",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://loinc.org",
                  code: "8480-6",
                },
              ],
            },
            valueQuantity: {
              value: systolic,
              unit: "mmHg",
              system: "http://unitsofmeasure.org",
              code: "mmHg",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://loinc.org",
                  code: "8462-4",
                },
              ],
            },
            valueQuantity: {
              value: diastolic,
              unit: "mmHg",
              system: "http://unitsofmeasure.org",
              code: "mmHg",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return UpdatePatientStatusAndAppointmentStatus({
            patientId: props.patient.id,
            appointmentId: props.patient.appointmentId,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          history.push("/enter_success");
        } else {
          throw new Error("Network Error");
        }
      })
      .catch((error) => {
        onOpenErrorDialog();
        console.log(error);
      });
  }

  async function onTemporarySubmit() {
    const encouter = {
      reference: BACKENDURL + "/encounter/" + props.patient.appointmentId,
      type: "Encounter",
    };
    const subject = {
      reference: BACKENDURL + "/patient/" + props.patient.id,
      type: "Patient",
    };
    const extension = [
      {
        url: BACKENDURL + "labNumber",
        value: "",
      },
    ];
    ObservationPostScreening({
      code: {
        coding: [
          {
            system: "https://loinc.org",
            code: "29463-7",
          },
        ],
      },
      valueQuantity: {
        value: weight,
        unit: "kg",
        system: "http://unitsofmeasure.org",
        code: "kg",
      },
      encounter: encouter,
      subject: subject,
      extension: extension,
    })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://loinc.org",
                  code: "8302-2",
                },
              ],
            },
            valueQuantity: {
              value: height,
              unit: "cm",
              system: "http://unitsofmeasure.org",
              code: "cm",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "276361009",
                },
              ],
            },
            valueQuantity: {
              value: waist,
              unit: "cm",
              system: "http://unitsofmeasure.org",
              code: "cm",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "248361005",
                },
              ],
            },
            valueQuantity: {
              value: fatmass,
              unit: "percent",
              system: "http://unitsofmeasure.org",
              code: "percent",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "163636005",
                },
              ],
            },
            valueQuantity: {
              value: musclemass,
              unit: "percent",
              system: "http://unitsofmeasure.org",
              code: "percent",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "271062006",
                },
              ],
            },
            valueQuantity: {
              value: fbsType == 2 ? fbs * 18.0182 : fbs,
              unit: "mg/dl",
              system: "http://unitsofmeasure.org",
              code: "mg/dl",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "113076002",
                },
              ],
            },
            valueQuantity: {
              value: ogttType == 2 ? ogtt * 18.0182 : ogtt,
              unit: "mg/dl",
              system: "http://unitsofmeasure.org",
              code: "mg/dl",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://www.snomed.org/",
                  code: "43396009",
                },
              ],
            },
            valueQuantity: {
              value: hba1c,
              unit: "percent",
              system: "http://unitsofmeasure.org",
              code: "percent",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://loinc.org",
                  code: "8480-6",
                },
              ],
            },
            valueQuantity: {
              value: systolic,
              unit: "mmHg",
              system: "http://unitsofmeasure.org",
              code: "mmHg",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return ObservationPostScreening({
            code: {
              coding: [
                {
                  system: "https://loinc.org",
                  code: "8462-4",
                },
              ],
            },
            valueQuantity: {
              value: diastolic,
              unit: "mmHg",
              system: "http://unitsofmeasure.org",
              code: "mmHg",
            },
            encounter: encouter,
            subject: subject,
            extension: extension,
          });
        } else {
          throw new Error("Network Error");
        }
      })
      .then((res) => {
        if (res.status === 200) {
          history.push("/enter_success");
        } else {
          throw new Error("Network Error");
        }
      })
      .catch((error) => {
        onOpenErrorDialog();
        console.log(error);
      });
  }

  let followup_form = [
    {
      label: props.language === "en" ? "Weight" : "น้ำหนัก (kg)",
      id: "weight",
      placeholder: props.language === "en" ? "Weight" : "น้ำหนัก",
      value: weight,
      onChangeValue: (e) => setWeight(e.target.value),
      clearIconButton: weight === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateWeight(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "Height" : "ส่วนสูง (cm)",
      id: "height",
      placeholder: props.language === "en" ? "Height" : "ส่วนสูง",
      value: height,
      onChangeValue: (e) => setHeight(e.target.value),
      clearIconButton: height === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateHeight(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "Waist" : "เส้นรอบเอว (cm)",
      id: "waist",
      placeholder: props.language === "en" ? "Waist" : "เส้นรอบเอว",
      value: waist,
      onChangeValue: (e) => setWaist(e.target.value),
      clearIconButton: waist === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateWaist(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "Fat mass" : "ค่ามวลไขมัน",
      id: "fatmass",
      placeholder: props.language === "en" ? "Body Fat" : "ค่ามวลไขมัน",
      value: fatmass,
      onChangeValue: (e) => setFatmass(e.target.value),
      clearIconButton: fatmass === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateFatmass(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "Muscle mass" : "ค่ามวลกล้ามเนื้อ",
      id: "musclemass",
      placeholder: props.language === "en" ? "Muscle mass" : "ค่ามวลกล้ามเนื้อ",
      value: musclemass,
      onChangeValue: (e) => setMusclemass(e.target.value),
      clearIconButton: musclemass === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateMusclemass(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "หน่วยวัด FBS" : "หน่วยวัด FBS",
      id: "fbsType",
      placeholder: props.language === "en" ? "หน่วยวัด FBS" : "หน่วยวัด FBS",
      value: fbsType,
      onChangeValue: (e) => setFbsType(e.target.value),
      clearIconButton: fbs === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateFbsType(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "FBS" : "ค่าน้ำตาลหลังอดอาหาร (Fasting)",
      id: "fbs",
      placeholder: props.language === "en" ? "FBS" : "ค่าน้ำตาลหลังอดอาหาร",
      value: fbs,
      onChangeValue: (e) => setFbs(e.target.value),
      clearIconButton: fbs === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateFbs(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "หน่วยวัด OGTT" : "หน่วยวัด OGTT",
      id: "ogttType",
      placeholder: props.language === "en" ? "หน่วยวัด OGTT" : "หน่วยวัด OGTT",
      value: ogttType,
      onChangeValue: (e) => setOgttType(e.target.value),
      clearIconButton: fbs === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateOgttType(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "OGTT" : "ค่าความทนทานต่อน้ำตาล (OGTT)",
      id: "ogtt",
      placeholder: props.language === "en" ? "OGTT" : "ค่าความทนต่อน้ำตาล",
      value: ogtt,
      onChangeValue: (e) => setOgtt(e.target.value),
      clearIconButton: ogtt === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateOgtt(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label: props.language === "en" ? "HbA1c" : "ค่า HbA1c",
      id: "HbA1c",
      placeholder: props.language === "en" ? "HbA1c" : "ค่า HbA1c",
      value: hba1c,
      onChangeValue: (e) => setHba1c(e.target.value),
      clearIconButton: hba1c === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateHba1c(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label:
        props.language === "en"
          ? "Systolic blood pressure"
          : "ความดันโลหิตค่าบน (mmHg)",
      id: "systolic",
      placeholder:
        props.language === "en"
          ? "Systolic blood pressure"
          : "ความดันโลหิตค่าบน",
      value: systolic,
      onChangeValue: (e) => setSystolic(e.target.value),
      clearIconButton: systolic === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateSystolic(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
    {
      label:
        props.language === "en"
          ? "Diastolic blood pressure"
          : "ความดันโลหิตค่าล่าง (mmHg)",
      id: "diastolic",
      placeholder:
        props.language === "en"
          ? "Diastolic blood pressure"
          : "ความดันโลหิตค่าล่าง",
      value: diastolic,
      onChangeValue: (e) => setDiastolic(e.target.value),
      clearIconButton: diastolic === "" ? <ClearIcon /> : <ClearIcon />,
      generate: generateDiastolic(),
      disabled: props.patient.appointmentStatus === APPOINTMENTSTATUS.fulfilled,
    },
  ];

  let followup = followup_form.map((followup_form) => (
    <Grid item xs={12} key={followup_form.id}>
      <Typography variant={"h5"} className={classes.text_left}>
        {followup_form.label}
      </Typography>
      <FormControl
        className={classes.textField}
        variant="outlined"
        color="primary"
      >
        <Select
          native
          label={followup_form.label}
          value={followup_form.value}
          name={followup_form.label}
          onChange={followup_form.onChangeValue}
          disabled={followup_form.disabled}
        >
          {followup_form.generate}
        </Select>
      </FormControl>
    </Grid>
  ));

  let enter_followup = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <Grid container spacing={4} className={classes.root}>
          <Grid item xs={12} style={{ padding: 32 }}>
            <Paper className={classes.profile_header}>
              <Grid item xs={12}>
                <img
                  src={props.patient.sex === 1 ? maleAvatar : femaleAvatar}
                  alt="avatar"
                  className={classes.avatar}
                />
                <Typography variant="h5">
                  หมายเลขอาสาสมัคร : {props.patient.id}
                </Typography>
                <Typography variant="h6">
                  วันเกิด : {props.patient.birthDate}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h5"}>
                  {props.language === "en"
                    ? "Registration"
                    : convertPatientStatusForm(props.patient.status)}
                </Typography>
              </Grid>
              {followup}
              <br />
              <Grid
                container
                spacing={6}
                style={{
                  display:
                    props.patient.appointmentStatus ===
                    APPOINTMENTSTATUS.fulfilled
                      ? "none"
                      : "flex",
                }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      if (validateFollowup()) {
                        onOpenSubmitDialog();
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                    className={classes.enter_button}
                  >
                    บันทึกข้อมูล
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.enter_temporary_button}
                    color="primary"
                    onClick={onTemporarySubmit}
                  >
                    บันทึกข้อมูลชั่วคราว
                  </Button>
                </Grid>
              </Grid>

              {/* <Grid
                item
                xs={12}
                style={{
                  display:
                    props.patient.appointmentStatus ===
                    APPOINTMENTSTATUS.fulfilled
                      ? "none"
                      : "block",
                }}
              >
                <Button
                  onClick={() => {
                    if (validateFollowup()) {
                      onOpenSubmitDialog();
                    } else {
                      setOpenSnackBar(true);
                    }
                  }}
                  className={classes.enter_button}
                >
                  บันทึกข้อมูล
                </Button>
                <Button
                  className={classes.enter_temporary_button}
                  color="primary"
                  onClick={onTemporarySubmit}
                >
                  บันทึกข้อมูลชั่วคราว
                </Button>
              </Grid>
            */}
            </Paper>
          </Grid>
          <div>
            <Dialog open={submitDialog} onClose={onCloseSubmitDialog}>
              <DialogTitle>
                {props.language === "th"
                  ? "คุณต้องการส่งข้อมูลใช่หรือไม่"
                  : "lorem ipsum"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {props.language === "th"
                    ? "โปรดตรวจสอบข้อมูลให้แน่ชัดก่อนทำการส่ง"
                    : "lorem ipsum"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onCloseSubmitDialog}
                  className={classes.dialog_button_cancel}
                >
                  {props.language === "th" ? "ยกเลิก" : "Cancel"}
                </Button>
                <Button
                  onClick={onSubmitFollowup}
                  className={classes.dialog_button_send}
                >
                  {props.language === "th" ? "ยืนยัน" : "Send"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog open={errorDialog} onClose={onCloseErrorDialog}>
              <DialogTitle>
                {props.language === "th"
                  ? "เกิดข้อผิดพลาดระหว่างการส่งข้อมูลไปที่เครื่องตรวจ C-peptide"
                  : "lorem ipsum"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {props.language === "th"
                    ? "กรุณารอสักครู่จากนั้น กดปุ่มบันทึกข้อมูลอีกครั้ง"
                    : "lorem ipsum"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onCloseErrorDialog}
                  className={classes.dialog_button_send}
                >
                  {props.language === "th" ? "ตกลง" : "ตกลง"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              คุณยังกรอกข้อมูลไม่ครบ
            </Alert>
          </Snackbar>
        </Grid>
      </div>
    </div>
  );

  return <div>{enter_followup}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.language,
  patient: state.patient,
});
export default connect(mapStateToProps, undefined)(EnterFollowup);
