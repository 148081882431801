export const ConvertToModel = (fhir) => {
  const patient = {
    id: fhir.id,
    nationalID: fhir.identifier[0].value,
    firstName: fhir.name.given,
    lastName: fhir.name.family,
    birthDate: fhir.birthDate,
    gender: fhir.gender,
    phone: fhir.telecom[0].value,
    status: fhir.extension[0].value,
    wristBandId: fhir.extension[1].value,
    group: fhir.extension[2].value,
    locationId: fhir.extension[3].value,
    timeCreated: fhir.extension[7].value.slice(0, 10),
    subStatus: fhir.extension[8].value,
    isCanBeVolunteer: fhir.extension[9].value,
    isVolunteer: fhir.extension[10].value,
    isSentInvitationToBeVolunteer: fhir.extension[11].value,
    lastAppointment: fhir.extension[12].value,
    screening: fhir.screening,
  };
  return patient;
};
