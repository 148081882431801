import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = (props) => {
  let BorderlinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: props.barcolor,
    },
  }))(LinearProgress);

  return (
    <React.Fragment>
      <BorderlinearProgress {...props} />
    </React.Fragment>
  );
};

export default BorderLinearProgress;
