import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  Snackbar,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import ClearIcon from "@material-ui/icons/Clear";
import PersonIcon from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AUTHENCONFIG } from "../../utility/config";
import style from "./style";
import logo from "../../assets/images/pic-red-cross-logo.jpg";
import { setUser } from "./../../redux/actions/user";
import {
  AuthenticationLogin,
  AuthenticationProfile,
} from "../../dataservice/authentication/authentication";

let Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = makeStyles(style)();
  let history = useHistory();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleKeydown = (event) => {
    if (event.keyCode === 13) {
      onLogin();
    }
  };

  async function onLogin() {
    try {
      const payload = {
        username: username,
        password: password,
        client_id: AUTHENCONFIG.client_id,
        client_secret: AUTHENCONFIG.client_secret,
      };
      const access_token = await AuthenticationLogin(payload).then((res) => {
        if (res.data.status === "success") {
          return res.data.data.access_token;
        } else {
          throw new Error("รหัสผ่านไม่ถูกต้อง");
        }
      });
      if (access_token) {
        const config = {
          headers: { Authorization: `Bearer ${access_token}` },
        };
        const profile = await AuthenticationProfile(config).then((res) => {
          if (res.data.status === "success") {
            return res.data.data.profile;
          } else {
            throw new Error("access_token ไม่สามารถใช้งานได้");
          }
        });
        props.setUser({
          isAuthenticate: true,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          role: profile.role,
          access_token: access_token,
          location: profile.location,
        });
        history.push("/home");
      }
    } catch (error) {
      setOpen(true);
      console.log(error.message);
    }
  }

  let login = (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.body}
        onKeyDown={handleKeydown}
      >
        <img src={logo} alt="logo" className={classes.logo} />

        <FormControl className={classes.textField} variant="outlined">
          <OutlinedInput
            id="username"
            placeholder="Email or Usrname"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setUsername("")}
                  edge="end"
                  disabled={username === "" ? true : false}
                >
                  {username === "" ? <PersonIcon /> : <ClearIcon />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={0}
          />
        </FormControl>
        <FormControl className={classes.textField} variant="outlined">
          <OutlinedInput
            id="password"
            placeholder="Password"
            type={showPassword ? "true" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={0}
          />
        </FormControl>
        <Button
          onClick={onLogin}
          type="submit"
          className={classes.login_button}
        >
          Login
        </Button>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง
          </Alert>
        </Snackbar>
        {/*<Button className={classes.forget_password}>
              Forget your password?
            </Button>*/}
      </Grid>
    </div>
  );
  return <div>{login}</div>;
};
const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(undefined, mapDispatchToProps)(Login);
