import { HistoryTaking } from "../route";
import axios from "axios";

export const HistoryTakingAddAnswer = (payload) => {
  const url = HistoryTaking();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(url, payload, config);
};
