import { Authentication } from "../route";
import axios from "axios";

export const AuthenticationLogin = (payload) => {
    const url = Authentication() + "/login"
    return axios.post(url, payload)
}

export const AuthenticationProfile = (config) => {
    const url = Authentication() + "/profile"
    return axios.get(url, config)
}