import { createMuiTheme } from "@material-ui/core/styles";

const arcGreen = "#00e676";
const theme = createMuiTheme({
  palette: {
    common: {
      green: arcGreen,
    },
    primary: {
      main: "#CC0000",
    },
    secondary: {
      main: "#F4F1F0",
    },
    title: {
      main: "#000",
    },
  },
});
export default theme;
