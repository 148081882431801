import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Button, useMediaQuery } from "@material-ui/core/";

import style from "./style";
import avatar from "../../../../assets/images/pic-nurse-profile.png";
import MainMenu from "../../main_menu";

let Home = (props) => {
  const classes = makeStyles(style)();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const practitioner = (
    <React.Fragment>
      <Button
        component={NavLink}
        to="/home"
        variant="outlined"
        type="submit"
        className={classes.action_button}
      >
        <Typography variant="h6">
          {props.language === "en" ? "Home" : "กลับสู่หน้าหลัก"}
        </Typography>
      </Button>
      <Button
        component={NavLink}
        to="/list_patient"
        variant="outlined"
        type="submit"
        className={classes.action_button}
      >
        <Typography variant="h6">
          {props.language === "en" ? "Screening" : "บันทึกข้อมูล"}
        </Typography>
      </Button>
    </React.Fragment>
  );

  const practitioner_mobile = (
    <React.Fragment>
      <Button
        component={NavLink}
        to="/list_patient"
        variant="outlined"
        type="submit"
        className={classes.action_button}
      >
        <Typography variant="h6">
          {props.language === "en" ? "Screening" : "บันทึกข้อมูล"}
        </Typography>
      </Button>
    </React.Fragment>
  );

  const doctor = (
    <React.Fragment>
      <Button
        component={NavLink}
        to="/record"
        variant="outlined"
        type="submit"
        className={classes.action_button}
      >
        <Typography variant="h6">
          {props.language === "en" ? "History" : "ประวัติการตรวจ"}
        </Typography>
      </Button>
    </React.Fragment>
  );

  let home = (
    <div>
      <div className={classes.toolbar} />
      <MainMenu />
      <div className={classes.content}>
        <div className={classes.body}>
          <div className={classes.body_paper}>
            <Typography variant="h4" className={classes.title}>
              {props.language === "en"
                ? "Loremipsum"
                : "ทำการบันทึกข้อมูลสำเร็จ"}
            </Typography>
            <img src={avatar} alt="logo" className={classes.logo} />
            <Typography variant="h5" className={classes.subtitle}>
              {props.language === "en"
                ? "Loremipsum"
                : "คุณต้องการดำเนินการสิ่งใด"}
            </Typography>
            {props.user.role === 2
              ? matches
                ? practitioner
                : practitioner_mobile
              : doctor}
          </div>
        </div>
      </div>
    </div>
  );
  return <div>{home}</div>;
};

const mapStateToProps = (state) => ({
  language: state.language,
  patient: state.patient,
  user: state.user,
});

export default connect(mapStateToProps, undefined)(Home);
