import { Patient, Location } from "../route";
import axios from "axios";

export const PatientPostGroup = (payload) => {
  const url = Patient() + "/group";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(url, payload, config);
};

export const PatientPostWristBand = (payload) => {
  const url = Patient() + "/wristband";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(url, payload, config);
};

export const PatientsSummary = (locationId) => {
  const url = Location() + `/${locationId}/patient/summary`;
  return axios.get(url);
};

export const PatientWaitForCreateFirstVisit = (locationId) => {
  const url =
    Patient() + "?status=3&firstVisitCreated=0&locationId=" + locationId;
  return axios.get(url);
};

export const PatientWaitForCancelFirstVisit = (locationId) => {
  const url =
    Patient() + "?status=3&firstVisitCreated=1&locationId=" + locationId;
  return axios.get(url);
};

export const PatientGetPatientsScreening = (locationId) => {
  const url =
    Patient() +
    "/" +
    //"?isCanBeVolunteer=1&isSentInvitationToBeVolunteer=1&locationId=" +
    "?isCanBeVolunteer=1&locationId=" +
    locationId;

  return axios.get(url);
};

export const PatientWaitForInvitation = (locationId) => {
  const url =
    Patient() +
    "?isCanBeVolunteer=1&isSentInvitationToBeVolunteer=1&locationId=" +
    locationId;
  return axios.get(url);
};

export const GetPrescreentaking = (locationId) => {
  const url = "https://backend.aisir.org/historytaking/prescreen" + "?locationId=" + locationId;
  return axios.get(url);
};

export const GetHistorytaking = () => {
  const url = "https://backend.aisir.org/historytaking";
  return axios.get(url);
};

export const PatientWaitForOpenAppointmentable = (locationId) => {
  const url = Patient() + "?isVolunteer=1&subStatus=0&locationId=" + locationId;
  return axios.get(url);
};

export const PatientWaitForOpenAppointmentableNonSelectLocation = () => {
  const url = Patient() + "?isVolunteer=1&subStatus=0&locationId=99";
  return axios.get(url);
};

export const PatientOpenAppointmentableWhoNotAppointmentYet = (locationId) => {
  const url = Patient() + "?isVolunteer=1&subStatus=1&locationId=" + locationId;
  return axios.get(url);
};

export const PatientOpenAppointmentableWhoNotAppointmentYetNonSelectLocation =
  () => {
    const url = Patient() + "?isVolunteer=1&subStatus=1&locationId=99";
    return axios.get(url);
  };

export const PatientWaitForUpdateProfile = (locationId) => {
  const url = Patient() + "?updateProfile=0";
  return axios.get(url);
};

export const UpdatePatientStatus = (payload) => {
  const url = Patient() + "/patient-status";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.put(url, payload, config);
};

export const UpdatePatientStatusAndAppointmentStatus = (payload) => {
  const url = Patient() + "/patient-appointment-status";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.put(url, payload, config);
};

export const UpdatePatientSubStatus = (payload, config) => {
  const url = Patient() + "/sub-status";
  return axios.put(url, payload, config);
};
