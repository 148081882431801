const language = "th";

export const languageReducer = (state = language, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export default languageReducer;
